export default {
  'common.register.emailRegistered': 'تم تسجيل هذا البريد الإلكتروني من قبل',
  'common.addressForm.othersText': 'آخر',
  'common.addressForm.saveToNextUse': 'حفظ المعلومات للمستقبل',
  'common.footer.subscribeText': 'اشترك للحصول على أحدث العروض و خصومات حصرية',
  'common.register.passwordLengthTip': 'من 8 إلى 16 خانة',
  'common.register.lastName': 'الاسم الأخير',
  'common.register.firstName': 'الاسم الأول',
  'common.login.noAccount': 'ليس لديك حساب ؟',
  'common.login.email': 'البريد الإلكتروني',
  'common.formValidate.maxLengthTip': 'المحتوى طويل جدا أن يكون أكثر من',
  'common.register.emailFormatTip': 'يرجى إدخال البريد الإلكتروني الصحيح',
  'common.addressForm.province': 'المحافظة',
  'common.forget.verificationCodePlaceholder': 'أدخل رمز التحقق المرسل إلى بريدك الإلكتروني',
  'common.addressForm.state': 'المحافظة',
  'common.register.resend': 'إعادة الإرسال',
  'common.header.allProducts': 'كل المنتجات',
  'common.reset.passwordFormatTip': 'يجب أن تتكون كلمة المرور من 8 إلى 16 رقمًا ويوصى بأن تحتوي على أحرف وأرقام',
  'common.networkTimeout': 'الاتصال بالإنترنت غير مستقر ،يرجى إعادة تحميل الصفحة والمحاولة مرة أخرى في وقت لاحق.',
  'common.forget.email': 'البريد الإلكتروني',
  'common.header.collection': 'المجموعات',
  'common.networkError': 'انقطع الاتصال بالشبكة. يرجى التحقق من الشبكة',
  'common.register.create': 'إنشاء',
  'common.register.confirmPassword': 'تأكيد كلمة المرور',
  'common.header.homePage': 'الصفحة الرئيسية',
  'common.pagination.items': 'العناصر',
  'common.register.sendEmail': 'إرسال الرمز',
  'common.forget.resetPassword': 'إعادة تعيين كلمة المرور',
  'common.inputRequired': 'يرجى ملء هذا الحقل',
  'common.pagination.total': 'الكل',
  'common.addressForm.city': 'المدينة',
  'common.login.incorrectPasswordTip': 'كلمة المرور غير صحيحة',
  'common.header.account': 'الحساب',
  'common.reset.confirm': 'تأكيد',
  'common.register.agree': 'من خلال الاستمرار، فأنت توافق على',
  'common.forget.continue': 'استمرار',
  'common.login': 'تسجيل الدخول',
  'common.header.search': 'بحث',
  'common.register.passwordFormatTip': 'يجب أن تتكون كلمة المرور من 8 إلى 16 رقمًا ويوصى بأن تحتوي على أحرف وأرقام',
  'common.login.register': 'إنشاء حساب',
  'common.phoneFormatTip': 'قم بادخال رقم الهاتف المحمول',
  'common.addressForm.apartment': 'رقم المبنى/الشقة (اختياري)',
  'common.footer.nowSubscribe': 'اشترك الآن',
  'common.forget.incorrectVerificationCodeTip': 'رمز التحقق غير صحيح',
  'common.register.password': 'كلمة المرور',
  'common.register.passwordTip': 'يجب أن تتكون من 8 إلى 16 خانة، و تحتوي على أحرف وأرقام و رموز',
  'common.register.inputRequiredTip': 'يرجى ملء هذا الحقل',
  'common.footer.Policy': 'السياسة',
  'common.login.password': 'كلمة المرور',
  'common.addressForm.phone': 'رقم الموبايل',
  'common.footer.copyright': ' 2022 بدعم من Pandova',
  'common.register.haveAccount': 'هل لديك حساب؟',
  'common.reset.confirmPasswordTip': 'كلمتا المرور غير متطابقتين',
  'common.footer.subscribeTip': 'قم بتسجيل الدخول للاشتراك',
  'common.register.termsOfService': 'بنود الخدمة',
  'common.forget.back': 'رجوع',
  'common.reset.passwordLength': 'من 8 إلى 16 خانة',
  'common.register.verificationCodeTip': 'أدخل رمز التحقق المرسل إلى بريدك الإلكتروني',
  'common.footer.privacyPolicy': 'سياسة الخصوصية',
  'common.login.notExistEmailTip': 'البريد الإلكتروني غير موجود',
  'common.reset.passwordTip': 'يجب أن تتكون كلمة المرور من 8 إلى 16 رقمًا ويوصى بأن تحتوي على أحرف وأرقام',
  'common.reset.confirmPassword': 'تأكيد كلمة المرور',
  'common.addressForm.country': 'الدولة',
  'common.reset.back': 'الرجوع إلى صفحة تسجيل الدخول',
  'common.register.privacyPolicy': 'سياسة الخصوصية',
  'common.addressForm.lastName': 'الاسم الأخير',
  'common.login.forgetPassword': 'هل نسيت كلمة المرور؟',
  'common.forget.tipText': 'يرجى إدخال بريدك الإلكتروني ورمز التحقق لإثبات ملكية حسابك',
  'common.forget.inputRequired': 'يرجى ملء هذا الحقل',
  'common.register.confirmPasswordTip': 'كلمتا المرور غير متطابقتين',
  'common.footer.termsOfService': 'بنود الخدمة',
  'common.reset.inputRequired': 'يرجى ملء هذا الحقل',
  'common.header.shoppingCart': 'سلة التسوق',
  'common.register.and': 'و',
  'common.reset.passwordPlaceholder': 'من 8 إلى 16 خانة',
  'common.addressForm.zipCode': 'الرمز البريدي',
  'common.okay': 'موافق',
  'common.forget.notExistEmail': 'البريد الإلكتروني غير موجود',
  'common.register.noAgreeTip': 'يرجى الموافقة على بنود الخدمة وسياسة الخصوصية',
  'common.reset.currentAccount': 'الحساب الحالي',
  'common.footer.subscribe': 'اشتراك',
  'common.register.login': 'تسجيل الدخول',
  'common.register.passwordPlaceholder': 'من 8 إلى 16 خانة',
  'common.register.createAccount': 'إنشاء حساب',
  'common.emailFormatTip': 'يرجى إدخال البريد الإلكتروني الصحيح',
  'common.register.incorrectVerificationTip': 'رمز التحقق غير صحيح',
  'common.addressForm.optional': '(اختياري)',
  'common.forget.verificationCode': 'رمز التحقق',
  'common.register.verificationCode': 'رمز التحقق',
  'common.formValidate.codeText': ' حرفا',
  'common.addressForm.useNewAddress': 'استعمال عنوان جديد',
  'common.reset.password': 'كلمة المرور',
  'common.register.email': 'البريد الإلكتروني',
  'common.addressForm.firstName': 'الاسم الأول',
  'common.reset.settingFinish': 'تم تعيين كلمة المرور بنجاح',
  'common.addressForm.detailAddress': 'العنوان',
}
