export default {
  'payment.checkout.payNow': 'Pay Now',
  'payment.contactInformation.signEmail': 'Send me news and discounts via email',
  'payment.changedLink': 'Order information has been updated. Please check the latest order information.',
  'payment.contactInformation.email': 'Email',
  'payment.orderSummary.tax': 'Tax',
  'payment.orderSummary.cancel': 'Remove',
  'payment.disabledLink.back': 'Back to Homepage',
  'payment.success.viewOrder': 'View Order',
  'payment.shipping.pickUpAddress': 'Pickup Location',
  'payment.navigation.payMethod': 'Payment',
  'payment.shipping.pickUPInStore': 'Pick up in store',
  'payment.orderSummary.total': 'Total',
  'payment.disabledLink': 'Sorry, the order has expired.',
  'payment.navigation.shoppingCart': 'Cart',
  'payment.contactInformation.haveAccount': 'Already have an account?',
  'payment.shipping.pleaseSelect': 'Please Select Shipping Method',
  'payment.orderSummary.usage': 'Apply',
  'payment.shipping.noShipping': 'Sorry, there is no shipping method available, please contact the merchant.',
  'payment.checkout.providedByOpay': 'Secured by OPay',
  'payment.contactInformation.logout': 'Log Out',
  'payment.shipping.continuePay': 'Continue to Pay',
  'payment.contactInformation.address': 'Shipping Address',
  'payment.orderSummary.title': 'Order Overview',
  'payment.orderSummary.calcAtNext': 'Calculate at next step',
  'payment.contactInformation.continueShipping': 'Continue to shipping method',
  'payment.contactInformation': 'Contact Information',
  'payment.shipping.method': 'Shipping Method',
  'payment.navigation.information': 'Information',
  'payment.shipping.free': 'Free Shipping Method',
  'payment.navigation.shipping': 'Shipping',
  'payment.checkout.method': 'Method',
  'payment.backShippingCart': 'Back to Cart',
  'payment.orderSummary.subTotal': 'Subtotal',
  'payment.update': 'Change',
  'payment.shipping.logistics': 'Courier service',
  'payment.orderSummary.discountCode': 'Discount Code',
  'payment.orderSummary.discount': 'Discount',
  'payment.success.title': 'Payment Success',
  'payment.contactInformation.formSelectTip': 'Please select one item in the list',
  'payment.checkout.noCheckout': 'There is no payment method in this store, please contact the merchant',
  'payment.contactInformation.login': 'Log In',
  'payment.shipping.deliverTo': 'Deliver to',
  'payment.orderSummary.shippingRate': 'Shipping',
  'payment.success.continueShopping': 'Continue to Shop',
  'payment.contactInformation.formInputTip': 'Please fill in this field',
}
