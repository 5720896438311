import antdEnUS from 'ant-design-vue/es/locale-provider/en_US'
import momentEU from 'moment/locale/eu'
import account from './en-US/account'
import common from './en-US/common'
import home from './en-US/home'
import payment from './en-US/payment'
import product from './en-US/product'
import search from './en-US/search'
import shoppingCart from './en-US/shopping-cart'

const components = {
  antLocale: antdEnUS,
  momentName: 'eu',
  momentLocale: momentEU,
}

export default {
  message: '-',
  ...components,
  ...account,
  ...common,
  ...home,
  ...payment,
  ...product,
  ...search,
  ...shoppingCart,
}
