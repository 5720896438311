export default {
  'product.comment.writeCommentEmail': '你的邮箱',
  'product.detail.viewCart': '查看购物车',
  'product.detail.additional': '附加信息',
  'product.comment.viewAll': '查看全部评价',
  'product.comment.paginationTotal': '第 $[X]-$[Y] 页',
  'product.comment.CustomerReview': '客户评价',
  'product.detail.description': '描述',
  'product.comment.noResultsFound': '暂无内容',
  'product.comment.writeFirstReview': '发表您的第一个评论吧',
  'product.detail.viewAllComments': '查看全部评论',
  'product.comment.withImages': '有图',
  'product.comment.writeReview': '写评价',
  'product.comment.noReviewsYet': '暂无评论',
  'product.comment.averageRating': '平均评分',
  'product.detail.checkout': '结账',
  'product.comment.writeCommentFirstName': '名字',
  'product.comment.shrink': '收起',
  'product.detail.notAddCartTips': '产品在预览链接不支持购买',
  'product.comment.writeCommentLastName': '姓氏',
  'product.commentPublish.back': '返回',
  'product.comment.writeCommentLastNamePlaceholder': '请输入用户姓氏',
  'product.comment.writeCommentPlaceholder': '请输入您对商品的评价',
  'product.comment.readAll': '阅读全部',
  'product.comment.writeCommentAddImagePlaceholder': '添加图片（选填）最多可以上传$[X] 张图片',
  'product.comment.writeCommentEmailPlaceholder': '请填写您的邮箱',
  'product.detail.buyNow': '立即购买',
  'product.comment.writeCommentFirstNamePlaceholder': '请输入用户名字',
  'product.detail.copyLink': '复制链接',
  'product.commentPublish.rating': '评分',
  'product.detail.addCart': '加入购物车',
  'product.detail.continueShopping': '继续购物',
  'product.list.soldOut': '售罄',
  'product.detail.skuSelectTip': '请选择商品规格',
  'product.comment.writeCommentCancel': '取消',
  'product.comment.writeCommentRating': '评分',
  'product.detail.comment': '评论',
  'product.comment.AllReviews': '全部评价',
  'product.comment.writeCommentAddImage': '添加图片',
  'product.comment.writeCommentSubmit': '提交评价',
  'product.detail.addCartTip': '加入购物车成功！',
  'product.detail.shareProduct': '分享此产品',
  'product.detail.noShareTips': '该产品状态为“草稿”，不支持分享。请将产品状态更改为“活跃”后再分享。',
  'product.detail.copySuccess': '复制成功',
  'product.commentPublish.comment': '评价',
  'product.detail.stock': '剩余',
  'product.commentPublish.release': '发布',
}
