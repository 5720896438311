export default {
  'account.addressManage.defaultAddress': '默认地址',
  'account.addressManage.deleteConfirm': '确认删除',
  'account.orderDetail.total': '总计',
  'account.orderDetail.product': '商品',
  'account.orderDetail.pay': '去付款',
  'account.addressManage.addAddress': '添加地址',
  'account.tabs.logout': '退出',
  'account.orderList.all': '全部订单',
  'account.orderDetail.shippingAddress': '运输地址',
  'account.orderDetail.price': '价格',
  'account.orderDetail.Operate': '操作',
  'account.tabs.addressManage': '地址管理',
  'account.addressManage.cancel': '取消',
  'account.orderDetail.logisticsInfoTitle': '物流详情',
  'account.orderDetail.noDeliveryDetail': '暂无物流信息',
  'account.orderList.orderNo': '订单号',
  'account.orderDetail': '订单详情',
  'account.orderList.orderTotal': '订单总额',
  'account.tabs.myOrder': '我的订单',
  'account.orderDetail.okay': '好的',
  'account.orderDetail.preTax': '税前',
  'account.orderDetail.shippingRate': '运费',
  'account.orderList.orderDate': '订单日期',
  'account.addressManage.edit': '编辑',
  'account.orderDetail.contactEmail': '邮箱',
  'account.orderDetail.orderNO': '订单号',
  'account.addressManage.allAddress': '全部地址',
  'account.orderList.operate': '操作',
  'account.orderDetail.paymentTime': '付款时间',
  'account.orderDetail.shipmentMessage': '你将会在邮件里收到物流信息',
  'account.orderDetail.tax': '税',
  'account.addressManage.delete': '删除',
  'account.orderDetail.deadlineSuffix': '，逾期后订单将自动取消',
  'account.orderDetail.billingAddress': '账单地址',
  'account.orderList.noOrderTip': '没有订单记录',
  'account.orderDetail.discount': '折扣',
  'account.addressManage.add': '保存',
  'account.orderList.viewDetail': '查看订单详情',
  'account.orderDetail.TrackDetailText': '查看物流详情',
  'account.orderDetail.subTotal': '小计',
  'account.tabs.myAccount': '我的账号',
  'account.orderDetail.number': '数量',
  'account.orderList.orderStatus': '订单状态',
  'account.orderDetail.TrackingNumber': '订单号',
  'account.orderDetail.comment': '评价',
  'account.orderDetail.back': '返回',
  'account.orderDetail.paidTip': '订单已付款，请查看最新订单信息',
  'account.orderDetail.deadlinePrefix': '请完成付款',
  'account.orderDetail.contactInfo': '联系人信息',
  'account.addressManage.setDefault': '设置为默认地址',
  'account.orderDetail.paymentMethod': '支付方式',
  'account.orderDetail.contactName': '姓名',
  'account.orderDetail.orderTime': '下单时间',
}
