<template>
  <div class="sub-menu">
    <div
      class="sub-menu-title-container"
      @click="expand"
    >
      <div class="sub-menu-title">{{ subMenu.title }}</div>
      <div class="sub-menu-icon">
        <a-icon
          v-show="!isShow"
          type="right"
        />
        <a-icon
          v-show="isShow"
          type="down"
        />
      </div>
    </div>
    <div
      v-show="isShow"
      class="sub-menu-item"
    >
      <op-menu-item
        v-for="(item, index) in subMenu.children"
        :key="index"
        :menu-item="item"
        @clickMenu="clickMenu"
      ></op-menu-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpSubMenu',
  props: {
    subMenu: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShow: false,
    }
  },

  computed: {},

  methods: {
    expand() {
      this.isShow = !this.isShow
    },
    clickMenu(menuItem) {
      this.$emit('clickMenu', menuItem)
    },
  },
}
</script>
<style lang="less" scoped>
.sub-menu {
  padding: 4px 0;
  .sub-menu-item {
    padding-left: 24px;
  }
}
.sub-menu-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #f4f6f8;
}
</style>
