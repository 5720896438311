import SiteLayout from '@/layouts/site-layout.vue'

// 获取管理端外壳布局的路由
export const getAllRoutes = () => [
  {
    path: '',
    component: SiteLayout,
    redirect: '/',
    children: [
      {
        path: '/',
        component: () => import('@/pages/home/index.vue'),
        meta: { title: '首页', pv: 'spmall_home_show' },
      },
      {
        path: '/account',
        component: () => import('@/pages/account/index.vue'),
        meta: { title: '我的账号', pv: 'spmall_myaccount_show' },
      },
      {
        path: '/products/all',
        component: () => import('@/pages/product-list/index.vue'),
        meta: { title: '商品列表', pv: 'spmall_productlist_show' },
      },
      {
        path: '/products/:productId',
        component: () => import('@/pages/product-detail/index.vue'),
        name: 'product-detail',
        props: true,
        meta: { title: '商品详情', pv: 'spmall_productdetail_show' },
      },
      {
        path: '/collections/:collectionId',
        component: () => import('@/pages/product-list-by-collection/index.vue'),
        meta: { title: '商品集列表', pv: 'spmall_groupproductlist_show' },
      },
      {
        path: '/search',
        component: () => import('@/pages/search/index.vue'),
        meta: { title: '搜索' },
      },
      {
        path: '/cart',
        component: () => import('@/pages/cart/index.vue'),
        meta: { title: '购物车', pv: 'spmall_shoppingcart_show' },
      },
      {
        path: '/checkout',
        component: () => import('@/pages/checkout/index.vue'),
        meta: { title: '结算', pv: 'spmall_payment_show' },
      },
      {
        path: '/checkout/success',
        component: () => import('@/pages/checkout/components/checkout-success.vue'),
        meta: { title: '结算成功' },
      },
      {
        path: '/checkout/invalid',
        component: () => import('@/pages/checkout/components/checkout-invalid.vue'),
        meta: { title: '失效支付链接' },
      },
      {
        path: '/order/:orderNo',
        component: () => import('@/pages/order-detail/index.vue'),
        meta: { title: '订单详情' },
      },
      {
        path: '/comments',
        component: () => import('@/pages/comment-list/index.vue'),
        meta: { title: '评价列表' },
      },
      {
        path: '/comments/new',
        component: () => import('@/pages/comment-new/index.vue'),
        meta: { title: '发布评价' },
      },
      {
        path: '/discount/:code',
        component: () => import('@/pages/discount/index.vue'),
        meta: { title: '折扣码触达' },
      },
      {
        path: '/customPage/:id',
        component: () => import('@/pages/custom-page/index.vue'),
        meta: { title: '自定义页面' },
      },
      {
        path: '/common/404',
        component: () => import('@/pages/error-page/404.vue'),
        meta: { title: '404' },
      },
    ],
  },
]
