import Vue from 'vue'
import Vuex from 'vuex'
import { localStorage, MallAnalytics } from '@/utils'
import { DEFAULT_LANGUAGE } from '@/constants'
import { loadLanguageAsync } from '@/locales'
import { getShoppingCartCount } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    shopInfo: {},
    lang: DEFAULT_LANGUAGE,
    layoutTemplate: {}, // 顶部、公告、栏底部 主题配置数据
    pageTemplate: {}, // 业务页面主题配置数据
    globalSettings: {},
    cartNumber: 1, // 购物车商品数量
    isAlreadyLogin: false,
    loginDialogInfo: { isShow: false, currentForm: '', returnTo: '' }, // LoginForm:登陆页面  registerForm:注册页面
    activeSectionId: null,
  },
  getters: {},
  mutations: {
    setActiveSectionId: (state, activeSectionId) => {
      state.activeSectionId = activeSectionId
    },
    setUserInfo: (state, userInfoParams) => {
      // window.$sentry.setUser(userInfoParams)
      const userInfo = userInfoParams || {}
      state.userInfo = userInfo
      localStorage.set('userInfo', userInfo)
      Object.keys(userInfo).length > 0 ? (state.isAlreadyLogin = 1) : (state.isAlreadyLogin = 0)
      localStorage.set('userInfo', userInfo)
      MallAnalytics.setCommonEventValues({ userInfo: { userId: userInfo.customerId, ...userInfo } })
    },
    setShopInfo: (state, shopInfoParams) => {
      const shopInfo = shopInfoParams || {}
      window.document.title = shopInfo.shopName
      state.shopInfo = shopInfo
      localStorage.set('shopInfo', shopInfo)
      MallAnalytics.setCommonEventValues({ shopInfo })
    },
    setLanguage: (state, lang) => {
      state.lang = lang
      localStorage.set('lang', lang)
    },
    setLayoutTemplate: (state, layoutTemplate) => {
      state.layoutTemplate = layoutTemplate
    },
    setPageTemplate: (state, pageTemplate) => {
      state.pageTemplate = pageTemplate
    },
    setGlobalSettings: (state, globalSettings) => {
      state.globalSettings = globalSettings
    },
    updateCartNumber: (state, number) => {
      state.cartNumber = number
    },
    closeLoginDialog: state => {
      state.loginDialogInfo = { isShow: false, currentPage: '' }
    },
    openLoginDialog: (state, openInfo) => {
      state.loginDialogInfo = { isShow: true, ...openInfo }
    },
  },
  actions: {
    // 退出
    async logout({ commit }) {
      localStorage.remove('accessToken')
      localStorage.remove('userInfo')
      commit('setUserInfo', {})
      commit('updateCartNumber', 0)
    },
    // 设置语言
    async setLanguage({ commit }, lang) {
      commit('setLanguage', lang)
      await loadLanguageAsync(lang)
    },
    // 更新购物车数量
    async updateCartNumber({ commit, state }) {
      if (state.isAlreadyLogin) {
        const { data } = await getShoppingCartCount()
        commit('updateCartNumber', (data && data.count) || 0)
      } else {
        const cartProductList = localStorage.get('shoppingCart', [])
        const cartNumber = cartProductList.reduce((total, cur) => {
          return (total += cur.skuNum)
        }, 0)
        commit('updateCartNumber', cartNumber)
      }
    },
  },
})
