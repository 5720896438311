<template>
  <div>
    <a-checkbox
      v-model="checkBoxValue"
      @change="onChange"
    >
      {{ label }}
      <slot></slot>
    </a-checkbox>
  </div>
</template>
<script>
import { JUDGE_TYPE } from '@/constants'
export default {
  name: 'OpCheckbox',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checkBoxValue: false,
    }
  },
  watch: {
    value: {
      handler(val) {
        this.checkBoxValue = val === JUDGE_TYPE.YES
      },
      immediate: true,
    },
  },
  methods: {
    onChange(val) {
      const value = val.target.checked ? JUDGE_TYPE.YES : JUDGE_TYPE.NO
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
}
</script>
