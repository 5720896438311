import router from '@/router'
import OPayAnalytics from './opay-analytics'
import { isProduction, getOPayAnalyticsPlatform } from './environment'
export * from './request.js'
export * from './environment'
export * from './date-formatter'
export * from './money-formatter'
export * from './storage'

/**
 *
 * @param {String} url 文件路径
 * @returns  文件
 * @example
 * const result = downFile('https://xxx.xx.xlsx')
 */
export const downFile = url => {
  if (!url) {
    return
  }
  const isSupportDownload = 'download' in document.createElement('a')
  if (!isSupportDownload) {
    window.open(url)
    return
  }
  const fileName = url.split('/').pop()
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * 去除空属性（0除外）
 * @param {Object} obj 对象
 * @returns
 * @example
 * const result = removeEmptyField({ a: undefined, b: null, c: -1, d: '', e: [], f: '1' })
 * // =>
 * {f: '1'}
 */
export const removeEmptyField = (obj, numberFieldList = []) => {
  const result = JSON.parse(JSON.stringify(obj))
  Object.keys(result).forEach(key => {
    const emptyValueList = numberFieldList.includes(key) ? [null, ''] : [-1, null, '']
    if (
      emptyValueList.includes(result[key]) ||
      (Array.isArray(result[key]) && result[key].length === 0)
    ) {
      delete result[key]
    }
  })
  return result
}

/**
 * 生成UUID
 * @returns UUID字符串
 * const result = generateUUID()
 * // =>
 * a1ea7884-b188-492e-a2e4-f5880d0d5f2f
 */
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0
    var v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * 链接跳转
 * @returns
 */
export const jumpLink = (url, query = {}) => {
  if (!url) {
    return
  }
  if (url.includes('http')) {
    window.location.href = url
    return
  }
  router.push({
    path: url,
    query,
  })
}

/*
 * Portal的埋点分析对象
 */
export const MallAnalytics = new OPayAnalytics({
  commonParams: {
    appName: 'SP_MALL', // 应用名称
    envType: isProduction() && process.env.NODE_ENV === 'production' ? 'release' : 'debug', // 环境类型
    platform: getOPayAnalyticsPlatform(), // 平台类型
  },
})

/**
 * 获取URL的参数值
 * @param {String} url 指定URL
 * @param {String} key 参数key
 * @returns 指定参数值
 * @example
 * const result = getUrlParam('https://blog.csdn.net/weixin_30505751/article/details/95270464?hello=world', 'hello')
 * // =>
 * world
 */
export const getUrlParam = (url, key) => {
  const search = url.split('?')[1] || ''
  const regExp = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
  const result = search.match(regExp)
  if (result) return result[2]
  return null
}

/*
 * 获取section 模块元素id
 */
export const getSectionEleId = sectionId => {
  return `section-${sectionId}`
}
