<template>
  <div class="comment-summary">
    <div class="comment-summary__product-comment">
      <div class="comment-summary__product-comment__name">{{ commentSummary.productName }}</div>
      <div class="comment-summary__product-comment__result">
        <a-rate
          :count="1"
          :default-value="1"
          disabled
        />
        <div
          class="comment-summary__product-comment__result__real-store"
        >{{ commentSummary.starLevelAvg }}</div>
        <div class="comment-summary__product-comment__result__total-store">/5.0</div>
      </div>
    </div>
    <div class="comment-summary__comment-category">
      <div
        v-for="item in list"
        :key="item.level"
        class="comment-summary__comment-category__item"
      >
        <div class="comment-summary__comment-category__item__level">{{ item.level }}</div>
        <a-rate
          class="comment-summary__comment-category__item__star"
          :count="1"
          :default-value="1"
          disabled
        />
        <div class="comment-summary__comment-category__item__progress">
          <div
            :style="{
              width: `${(commentSummary[item.key] / commentSummary.totalQuantity) * 100}%`,
            }"
            class="comment-summary__comment-category__item__progress__progress-scale"
          ></div>
        </div>
        <div class="comment-summary__comment-category__item__number">{{ commentSummary[item.key] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentSummary',
  components: {},
  props: {
    commentSummary: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [
        { level: '5.0', key: 'fiveStarNum' },
        { level: '4.0', key: 'fourStarNum' },
        { level: '3.0', key: 'threeStarNum' },
        { level: '2.0', key: 'twoStarNum' },
        { level: '1.0', key: 'oneStarNum' },
      ],
    }
  },

  computed: {},

  methods: {},
}
</script>
<style lang="less" scoped>
.comment-summary {
  display: flex;
  width: 100%;
  padding-bottom: 32px;
  &__product-comment {
    width: 450px;
    &__name {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: r#47516A;
    }
    &__result {
      display: flex;
      align-items: center;
      margin-top: 20px;

      &__real-store {
        margin-left: 16px;
        font-size: 56px;
        font-weight: 400;
        line-height: 72px;
      }
      &__total-store {
        font-size: 32px;
        font-weight: 400;
      }
    }
  }
  &__comment-category {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 32px;
    &__item {
      display: flex;
      align-items: center;
      font-size: 14px;
      &__level {
        width: 30px;
        margin-right: 4px;
        color: #181e2d;
      }
      &__star {
        margin-right: 20px;
      }
      &__progress {
        flex: 1;
        height: 6px;
        background: #f4f6f8;
        border-radius: 4px;
        &__progress-scale {
          height: 100%;
          background: var(--color-primary-button-background);
          border-radius: 4px;
        }
      }
      &__number {
        margin-left: 20px;
        line-height: 20px;
        color: #5a7184;
      }
    }
  }
}
@media (max-width: 1024px) {
  .comment-summary {
    display: block;
    width: 100%;
    &__product-comment {
      width: 100%;
    }
    &__comment-category {
      width: 100%;
      height: 182px;
      margin-top: 32px;
      margin-left: 0;
    }
  }
}
</style>
