import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueLazyLoad from 'vue-lazyload'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import i18n from '@/locales'
import router from '@/router'
import store from '@/store'
import App from '@/App.vue'
import components from '@/components'
import directives from '@/directives'
import filters from '@/filters'
import { isDevelopment, isProduction } from '@/utils'
// import OPaySentry from '@/utils/opay-sentry'
import { getOssImageCompressUrl } from '@/utils/image-compress'
import bootstrap from '@/bootstrap'
import '@/components/ant-design-vue.js'
import '@/styles/css-variable.css'
import '@/styles/common.less'
import '@/styles/antd.less'
import 'swiper/dist/css/swiper.css'

Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg.includes('Duplicate keys detected') && isDevelopment()) {
    return false
  }
  console.error(msg, vm, trace)
}
Vue.config.productionTip = false
Vue.use(filters)
Vue.use(VueAwesomeSwiper)
Vue.use(VueLazyLoad, {
  filter: {
    progressive(listener, options) {
      listener.el.setAttribute('lazy-progressive', 'true')
      const compressOption = {
        src: listener.src,
        width: listener.el && listener.el.width,
      }
      listener.src = getOssImageCompressUrl(compressOption)
    },
  },
  preLoad: 1.3,
  attempt: 2,
  error: 'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/components/load-error.png',
  loading: 'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/components/loading.jpg',
})

components.forEach(component => {
  Vue.component(component.name, component)
})
directives.forEach(directive => {
  Vue.directive(directive.name, directive)
})

// const sentry = OPaySentry.getInstance(Vue, {
//   isReport: isProduction() && process.env.NODE_ENV === 'production',
//   router,
//   environment: process.env.VUE_APP_ENV,
//   dsn: 'https://e2105d67540c42d994331e4fbfebe7b6@sentry.pandova.cloud/10',
//   release: process.env.VUE_APP_SENTRY_RELEASE,
//   ignoreErrors: [
//     'Network Error',
//     'ChunkLoadError',
//     'ReferenceError',
//     'RangeError',
//     'SecurityError',
//     'InternalError',
//     'Request aborted',
//     'Maximum call stack size exceeded',
//     'ResizeObserver loop limit exceeded',
//     'onPagePause is not defined',
//     'onPageResume is not defined',
//     'Load javascript failed',
//     'Request failed with status code 500',
//     'Request failed with status code 502',
//     'Unstable network connection. Please refresh and try again later.',
//     'Service exception. Please try again later.',
//     'timeout of 6000ms exceeded',
//     'Non-Error promise rejection captured with keys: code',
//     'Non-Error exception captured with keys: code',
//     'Avoided redundant navigation to current location',
//     /Cannot read property 'value' of null/,
//     /Cannot read property 'offsetTop' of null/,
//     /Loading CSS chunk \d+ failed/,
//     /Identifier 'isMainframe' has already been declared/,
//     /Cannot read properties of undefined \(reading '-'\)/,
//     /Cannot read properties of undefined \(reading 'matched'\)/,
//   ],
// })
// window.$sentry = sentry

bootstrap()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

console.info('hotfix/hw-test')
