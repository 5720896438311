<template>
  <button
    :disabled="disabled"
    :class="[buttonType, disabled && 'op-button--disabled', loading && 'op-button--loading']"
    class="op-button"
    @click="handleClick"
  >
    <a-icon
      v-show="loading"
      class="op-button__loading-icon"
      type="loading"
    />
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'OpButton',
  components: {},
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },

  computed: {
    buttonType() {
      return 'op-button--' + this.type
    },
  },

  methods: {
    handleClick(event) {
      if (this.disabled || this.loading) {
        return
      }
      this.$emit('click', event)
    },
  },
}
</script>
<style lang="less" scoped>
.op-button {
  position: relative;
  display: inline-block;
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  white-space: nowrap;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &__loading-icon {
    margin-right: 8px;
  }
  &--disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &--loading {
    opacity: 0.7;
  }
}
.op-button--primary {
  color: var(--color-primary-button-text, #fff);
  background-color: var(--color-primary-button-background, #2a334a) !important;
  border-color: var(--color-primary-button-outline, #2a334a) !important;
}
.op-button--outline {
  color: var(--color-secondary-button-text rgba(0, 0, 0, 0.65));
  background-color: var(--color-secondary-button-background, #fff) !important;
  border: 1px solid var(--color-secondary-button-outline, #2a334a) !important;
}
</style>
