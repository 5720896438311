export default {
  'account.addressManage.defaultAddress': 'العنوان الافتراضي',
  'account.addressManage.deleteConfirm': 'تأكيد للحذف؟',
  'account.orderDetail.total': 'المبلغ الإجمالي',
  'account.orderDetail.product': 'المنتج',
  'account.orderDetail.pay': 'إتمام الدفع',
  'account.addressManage.addAddress': 'إضافة عنوان',
  'account.tabs.logout': 'تسجيل الخروج',
  'account.orderList.all': 'كل الطلبات',
  'account.orderDetail.shippingAddress': 'عنوان الشحن',
  'account.orderDetail.price': 'السعر',
  'account.orderDetail.Operate': 'أوامر',
  'account.tabs.addressManage': 'العناوين',
  'account.addressManage.cancel': 'الغاء ',
  'account.orderDetail.logisticsInfoTitle': 'تفاصيل الشحن',
  'account.orderDetail.noDeliveryDetail': 'لا يوجد معلومات شحن',
  'account.orderList.orderNo': 'رقم الطلب',
  'account.orderDetail': 'تفاصيل الطلب',
  'account.orderList.orderTotal': 'كمية الطلب',
  'account.tabs.myOrder': 'الطلبات',
  'account.orderDetail.okay': 'موافق',
  'account.orderDetail.preTax': 'قبل الضرائب',
  'account.orderDetail.shippingRate': 'تكلفة الشحن',
  'account.orderList.orderDate': 'تاريخ الطلب',
  'account.addressManage.edit': 'تعديل',
  'account.orderDetail.contactEmail': 'البريد الإلكتروني',
  'account.orderDetail.orderNO': 'رقم الطلب',
  'account.addressManage.allAddress': 'كل العناوين',
  'account.orderList.operate': 'أوامر',
  'account.orderDetail.paymentTime': 'وقت الدفع',
  'account.orderDetail.shipmentMessage': 'سيتم إرسال معلومات الشحن إلى بريدك الإلكتروني',
  'account.orderDetail.tax': 'ضرائب',
  'account.addressManage.delete': 'حذف',
  'account.orderDetail.deadlineSuffix': 'سيتم إلغاء الطلب تلقائيًا بعد انتهاء الوقت',
  'account.orderDetail.billingAddress': 'عنوان الدفع',
  'account.orderList.noOrderTip': 'لا يوجد سجل طلبات',
  'account.orderDetail.discount': 'مبلغ الخصم',
  'account.addressManage.add': 'حفظ',
  'account.orderList.viewDetail': 'عرض الطلب',
  'account.orderDetail.TrackDetailText': 'عرض تفاصيل الشحن',
  'account.orderDetail.subTotal': 'المجموع الفرعي',
  'account.tabs.myAccount': 'حسابي',
  'account.orderDetail.number': 'الكمية',
  'account.orderList.orderStatus': 'حالة الطلب',
  'account.orderDetail.TrackingNumber': 'رقم التتبع',
  'account.orderDetail.comment': 'التعليقات',
  'account.orderDetail.back': 'رجوع',
  'account.orderDetail.paidTip': 'تم إتمام عملية الدفع لهذا الطلب، يرجى التحقق من تفاصيل الطلبات',
  'account.orderDetail.deadlinePrefix': 'يرجى إتمام عملية الدفع في خلال',
  'account.orderDetail.contactInfo': 'معلومات الاتصال',
  'account.addressManage.setDefault': 'تعيين كعنوان افتراضي',
  'account.orderDetail.paymentMethod': 'طريقة الدفع',
  'account.orderDetail.contactName': 'اسم جهة الاتصال',
  'account.orderDetail.orderTime': 'وقت الطلب',
}
