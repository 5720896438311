import store from '@/store/index.js'

/**
 * * @param {*} css变量key和value
 * 设置css全局变量
 * @example setCssVars({'color-text':'#fff'})
 */
export const setCssVars = cssVariable => {
  Object.keys(cssVariable || {}).forEach(item => {
    document.documentElement.style.setProperty(`--${item}`, cssVariable[item])
  })
}
/**
 * * @param {*} 主题配置数据
 * 初始化商城主题
 * @example initTheme({'global':{},sectionOrder:[],sections:{}})
 */
export const initTheme = (themeData = {}) => {
  const templateJson = themeData || {}

  if (templateJson.global) {
    store.commit('setGlobalSettings', templateJson.global)

    const cssVariableKey = ['colorInfo', 'fontInfo']
    const cssVariableSettings = cssVariableKey.reduce((total, cur) => {
      const curObj = templateJson.global[cur] || {}
      return { ...total, ...curObj }
    }, {})

    setCssVars(cssVariableSettings)
  }

  if (!templateJson.sectionOrder || !templateJson.sections) {
    return
  }

  // 获取布局组件配置数据，与商家后台约定 布局组件在sectionOrder固定index 0,1和最后一个
  const sectionOrder = templateJson.sectionOrder || []
  const layoutSectionOrder = [sectionOrder[0], sectionOrder[1], sectionOrder.pop()]
  const layoutSections = {}
  layoutSectionOrder.forEach(key => {
    layoutSections[key] = templateJson.sections[key]
  })

  store.commit('setLayoutTemplate', { layoutSections, layoutSectionOrder })

  // 获取首页布局组件配置数据
  const pageSectionOrder = sectionOrder.slice(2)
  const pageSections = {}
  pageSectionOrder.forEach(key => {
    pageSections[key] = templateJson?.sections[key]
  })
  store.commit('setPageTemplate', { pageSections, pageSectionOrder })
}
