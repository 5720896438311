export default {
  global: {
    colorInfo: {
      'color-text': 'rgba(71, 81, 106, 1)',
      'color-product-origin-price': 'rgba(137, 145, 164, 1)',
      'color-product-sale-price': 'rgba(192, 55, 70, 1)',
      'color-primary-button-background': 'rgba(117, 148, 156, 1)',
      'color-primary-button-text': 'rgba(255, 255, 255, 0.8)',
      'color-primary-button-outline': 'rgba(117, 148, 156, 1)',
      'color-secondary-button-background': 'rgba(255, 255, 255, 1)',
      'color-secondary-button-text': 'rgba(117, 148, 156, 1)',
      'color-secondary-button-outline': 'rgba(117, 148, 156, 1)',
      'color-title': 'rgba(24, 30, 45, 1)',
      'color-sub-title': 'rgba(71, 81, 106, 1)',
    },
    fontInfo: {
      'font-size-text': '14px',
      'font-weight-text': 'normal',
    },
    undefined: {
      'color-text': 'rgba(71, 81, 106, 1)',
      'color-product-origin-price': 'rgba(137, 145, 164, 1)',
      'color-product-sale-price': 'rgba(192, 55, 70, 1)',
      'color-primary-button-background': 'rgba(117, 148, 156, 1)',
      'color-primary-button-text': 'rgba(255, 255, 255, 0.8)',
      'color-primary-button-outline': 'rgba(117, 148, 156, 1)',
      'color-secondary-button-background': 'rgba(255, 255, 255, 1)',
      'color-secondary-button-text': 'rgba(117, 148, 156, 1)',
      'color-secondary-button-outline': 'rgba(117, 148, 156, 1)',
      'color-title': 'rgba(24, 30, 45, 1)',
      'color-sub-title': 'rgba(71, 81, 106, 1)',
    },
  },
  sectionOrder: [
    'ff0da77c-6803-4242-95ab-7d3587ad47fe',
    '1ce0cab9-d9e2-4c7f-9357-b34afc19d345',
    '7eddcf41-dae3-4b11-8baa-dab3d8e785a1',
    'f29a5540-9712-430d-a509-4eea2687c526',
    '4190abeb-66da-4484-9197-64f35158f871',
    'a64c6c01-42b6-4361-99e6-c4d61c29b3fd',
    '1c7eff9b-a5d7-4210-a89a-f9e22d515511',
    '8b560edb-2528-4e21-a3e7-0ee6a07b73b9',
  ],
  sections: {
    'ff0da77c-6803-4242-95ab-7d3587ad47fe': {
      type: 'announcement-bar',
      disabled: false,
      settings: {},
      blocks: {
        '3246858d-7a5e-40a3-bc9a-a742c79d732f': {
          type: 'announcement',
          settings: {
            announcementLink: {},
            announcementText: 'END  OF  SEASON  SALE: Up to 70% OFF Storewide',
            colorBg: 'rgba(117, 148, 156, 1)',
            colorText: 'rgba(255, 255, 255, 1)',
          },
          disabled: false,
        },
      },
      blockOrder: ['3246858d-7a5e-40a3-bc9a-a742c79d732f'],
    },
    '1ce0cab9-d9e2-4c7f-9357-b34afc19d345': {
      type: 'layout-header',
      disabled: false,
      settings: {
        colorBg: 'rgba(255, 255, 255, 0.8)',
        colorText: 'rgba(24, 30, 45, 1)',
        subMenuColorText: 'rgba(24, 30, 45, 1)',
        subMenuColorBg: 'rgba(255, 255, 255, 1)',
        logoUrl:
          'https://saas-shop-prod.oss-eu-west-1.aliyuncs.com/theme/40000004/c1c2a7ad-7dc0-4cf3-b594-bbfa9882548c.png',
        logoWidth: 130,
        showLineSeparator: false,
        marginBottom: 12,
        mobileMenuColorBg: 'rgba(255, 255, 255, 1)',
        mobileMenuColorText: 'rgba(24, 30, 45, 1)',
      },
      blocks: {},
      blockOrder: [],
    },
    '8b560edb-2528-4e21-a3e7-0ee6a07b73b9': {
      type: 'layout-footer',
      disabled: false,
      settings: {
        colorBg: 'rgba(243, 245, 246, 1)',
        colorText: 'rgba(24, 30, 45, 1)',
        marginTop: 32,
        paddingTop: 32,
        paddingBottom: 32,
        emailHeading: 'Let’s keep in touch',
        emailSunHeading: 'Subscribe to our weekly and receive exclusive noffers on products you love',
        emailShow: true,
      },
      blocks: {},
      blockOrder: [],
    },
    '7eddcf41-dae3-4b11-8baa-dab3d8e785a1': {
      disabled: false,
      blocks: {
        '800706e4-a887-4720-9ffc-d3afc95d0bba': {
          disabled: false,
          settings: {
            imageUrl:
              'https://saas-shop-prod.oss-eu-west-1.aliyuncs.com/theme/40000004/6b7e6deb-ab00-4b22-9d57-beb15d05a46b.png',
            isShowText: false,
            textBoxBgColor: '#fff',
            heading: '',
            headingSize: 18,
            subheading: '',
            buttonLabel: '',
            link: {
              value: 'allProduct',
              path: '/products/all',
            },
            outlineButtonStyle: 'primary',
            imageOverlayOpacity: 1,
          },
          type: 'slide',
        },
        '773fc76e-ab1d-4e4c-aba3-4cb69a5182c8': {
          type: 'slide',
          disabled: false,
          settings: {
            imageUrl:
              'https://saas-shop-prod.oss-eu-west-1.aliyuncs.com/theme/40000004/4c1ee6cc-d021-47c2-8764-8e8f6f858ea5.png',
            isShowText: false,
            textBoxBgColor: '#fff',
            heading: '',
            headingSize: 16,
            subheading: '',
            buttonLabel: '',
            link: {
              value: 'allProduct',
              path: '/products/all',
            },
            outlineButtonStyle: true,
            imageOverlayOpacity: 1,
          },
        },
      },
      blockOrder: ['800706e4-a887-4720-9ffc-d3afc95d0bba', '773fc76e-ab1d-4e4c-aba3-4cb69a5182c8'],
      type: 'slide-show',
      settings: {
        slideShowHeight: 'small',
        slideShowWidth: 'full',
        changeSlidesSpeed: 2000,
      },
    },
    'a64c6c01-42b6-4361-99e6-c4d61c29b3fd': {
      disabled: false,
      blocks: {},
      blockOrder: [],
      type: 'featured-collection',
      settings: {
        title: '日常家居推荐',
        collection: -1,
        paddingTop: 32,
        paddingBottom: 32,
        imageRatio: 8,
      },
    },
    '1c7eff9b-a5d7-4210-a89a-f9e22d515511': {
      disabled: false,
      blocks: {
        'd65060da-1538-455d-a250-bd3a2cf34676': {
          type: 'product',
          disabled: false,
          settings: {
            productId: -1,
          },
        },
        '0b4aa7e2-f407-41f7-bd2c-c156db9e05e4': {
          type: 'product',
          disabled: false,
          settings: {
            productId: -1,
          },
        },
        '7d30b995-e0f7-45fb-adf8-e320d86f089d': {
          type: 'product',
          disabled: false,
          settings: {
            productId: -1,
          },
        },
        '77b4671e-d654-49e8-9147-3fdce4bc9b93': {
          type: 'product',
          disabled: false,
          settings: {
            productId: -1,
          },
        },
      },
      blockOrder: [
        'd65060da-1538-455d-a250-bd3a2cf34676',
        '0b4aa7e2-f407-41f7-bd2c-c156db9e05e4',
        '7d30b995-e0f7-45fb-adf8-e320d86f089d',
        '77b4671e-d654-49e8-9147-3fdce4bc9b93',
      ],
      type: 'featured-product',
      settings: {
        title: '今日新品',
        imageRatio: 4,
        paddingTop: 16,
        paddingBottom: 16,
      },
    },
    'f29a5540-9712-430d-a509-4eea2687c526': {
      disabled: false,
      blocks: {},
      blockOrder: [],
      type: 'image-banner',
      settings: {
        imageUrl:
          'https://saas-shop-prod.oss-eu-west-1.aliyuncs.com/theme/40000004/e4b52c24-ac26-454d-8581-6ee088c47654.png',
        linkUrl: {
          value: 'allProduct',
          path: '/products/all',
        },
        heading: '',
        text: '',
        containerWidth: 'full',
        textAlign: 'left',
      },
    },
    '4190abeb-66da-4484-9197-64f35158f871': {
      disabled: false,
      blocks: {},
      blockOrder: [],
      type: 'image-banner',
      settings: {
        imageUrl:
          'https://saas-shop-prod.oss-eu-west-1.aliyuncs.com/theme/40000004/8bec8a4d-b2f0-435b-a4aa-709e3c6b3958.png',
        linkUrl: {
          value: 'allProduct',
          path: '/products/all',
        },
        heading: '',
        text: '',
        containerWidth: 'full',
        textAlign: 'center',
      },
    },
  },
}
