<template>
  <div class="forgot-password">
    <div
      v-show="currentStep !== 'resetPasswordSuccess'"
      class="back-last-step"
      @click="goLastStep"
    >
      <a-icon
        :style="{ fontSize: '18px', color: '#8991a4' }"
        type="left"
      />
      <span class="last-step-text">{{ $t('common.forget.back') }}</span>
    </div>
    <div
      v-show="currentStep === 'emailValid'"
      class="forgot-password-body"
    >
      <div class="form-header-title">{{ $t('common.login.forgetPassword') }}</div>
      <div class="form-header-tip">{{ $t('common.forget.tipText') }}</div>
      <a-form-model
        ref="emailForm"
        layout="vertical"
        :model="emailFormData"
        :rules="emailFormRules"
      >
        <a-form-model-item
          :label="$t('common.forget.email')"
          prop="mail"
        >
          <a-input
            v-model.trim="emailFormData.mail"
            size="large"
          />
        </a-form-model-item>
        <div class="verification-code">
          <div class="verification-code-input">
            <a-form-model-item
              :label="$t('common.forget.verificationCode')"
              prop="verificationCode"
            >
              <a-input
                v-model.trim="emailFormData.verificationCode"
                size="large"
              />
            </a-form-model-item>
          </div>
          <div
            v-if="sendEmailCode === 'countDown'"
            class="verification-code-send-dead"
          >
            <a-statistic-countdown
              :value="deadline"
              format="s"
              @finish="countdownFinish"
            />
            <span class="verification-code-send-dead__date">S</span>
          </div>
          <op-button
            v-if="sendEmailCode === 'first'"
            class="send-email-btn"
            @click="sendEmail"
          >{{ $t('common.register.sendEmail') }}</op-button>
          <op-button
            v-if="sendEmailCode === 'again'"
            class="send-email-btn"
            @click="sendEmail"
          >{{ $t('common.register.resend') }}</op-button>
        </div>
      </a-form-model>
      <div class="form-btn">
        <op-button @click="nextStep">{{ $t('common.forget.continue') }}</op-button>
      </div>
    </div>
    <div
      v-show="currentStep === 'resetPassword'"
      class="reset-password-body"
    >
      <div class="form-header-title">{{ $t('common.forget.resetPassword') }}</div>
      <div
        class="form-header-tip"
      >{{ `${$t('common.reset.currentAccount')} ${emailFormData.mail}` }}</div>
      <a-form-model
        ref="passwordForm"
        layout="vertical"
        :model="passwordFormData"
        :rules="passwordFormRules"
      >
        <a-popover trigger="click">
          <div
            slot="content"
            class="forgot-password__password-tip"
          >{{ $t('common.register.passwordTip') }}</div>
          <a-form-model-item
            :label="$t('common.reset.password')"
            prop="password"
          >
            <a-input
              v-model.trim="passwordFormData.password"
              type="password"
              autocomplete="off"
              size="large"
            />
          </a-form-model-item>
        </a-popover>
        <a-form-model-item
          :label="$t('common.reset.confirmPassword')"
          prop="confirmPassword"
        >
          <a-input
            v-model.trim="passwordFormData.confirmPassword"
            type="password"
            autocomplete="off"
            size="large"
          />
        </a-form-model-item>
      </a-form-model>
      <div class="form-btn">
        <op-button @click="summitPassword">{{ $t('common.reset.confirm') }}</op-button>
      </div>
    </div>
    <reset-password-success
      v-if="currentStep === 'resetPasswordSuccess'"
      @goLogin="goLogin"
    ></reset-password-success>
  </div>
</template>

<script>
import ResetPasswordSuccess from './reset-password-success.vue'
import {
  sendForgotEmail,
  verifyVerificationCode,
  resetPassword,
  getPublicKeyForNoToken,
} from '@/api'

import rsaUtil from '@/utils/rsa-util'
import {
  validateMail,
  validateRequired,
  validatePassword,
  validateRequiredAndMaxLength,
} from '@/utils/validator'
export default {
  components: {
    ResetPasswordSuccess,
  },
  props: {},
  data() {
    return {
      currentStep: 'emailValid',
      emailFormData: {},
      passwordFormData: {},
      deadline: Date.now() + 1000 * 0,
      sendEmailCode: 'first',
    }
  },

  computed: {
    passwordFormRules() {
      return {
        password: [validateRequired(), validatePassword()],
        confirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('common.inputRequired')))
              } else if (value !== this.passwordFormData.password) {
                callback(new Error(this.$t('common.reset.confirmPasswordTip')))
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
      }
    },
    emailFormRules() {
      return {
        mail: [validateRequiredAndMaxLength(), validateMail()],
        verificationCode: [validateRequiredAndMaxLength()],
      }
    },
  },

  methods: {
    countdownFinish() {
      this.sendEmailCode = 'again'
    },
    sendEmail() {
      this.$refs.emailForm &&
        this.$refs.emailForm.validateField('mail', async errorMessage => {
          if (!errorMessage) {
            await sendForgotEmail({ mail: this.emailFormData.mail })
            this.sendEmailCode = 'countDown'
            this.deadline = Date.now() + 1000 * 60
          }
        })
    },
    nextStep() {
      this.$refs.emailForm &&
        this.$refs.emailForm.validate(async valid => {
          if (valid) {
            await verifyVerificationCode({
              mail: this.emailFormData.mail,
              verificationCode: this.emailFormData.verificationCode,
            })
            this.currentStep = 'resetPassword'
          }
        })
    },
    goLastStep() {
      if (this.currentStep === 'emailValid') {
        this.goLogin()
      }
      if (this.currentStep === 'resetPassword') {
        this.currentStep = 'emailValid'
      }
    },
    summitPassword() {
      this.$refs.passwordForm &&
        this.$refs.passwordForm.validate(async valid => {
          if (valid) {
            const publicKey = await getPublicKeyForNoToken({ mail: this.emailFormData.mail })
            const password = rsaUtil.encrypt(
              this.passwordFormData.password,
              publicKey && publicKey.data
            )
            const { message } = await resetPassword({
              mail: this.emailFormData.mail,
              password: password,
            })
            this.$message.success(message)
            this.currentStep = 'resetPasswordSuccess'
          }
        })
    },
    goLogin() {
      this.$emit('changeComponent', 'loginForm')
    },
  },
}
</script>
<style lang="less" scoped>
.forgot-password {
  .back-last-step {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .last-step-text {
      font-weight: 500;
      color: #8991a4;
    }
  }
  &__password-tip {
    max-width: 400px;
  }
  .form-header-title {
    font-size: 22px;
    font-weight: 500;
    color: #01040d;
    text-align: center;
  }
  .form-header-tip {
    margin: 16px auto 32px;
    color: #6d7b98;
    text-align: center;
  }
  .form-btn {
    margin-top: 16px;
    .op-button {
      width: 100%;
    }
  }
  .forgot-password-body {
    .verification-code {
      display: flex;
      &-input {
        flex: 1;
        margin-right: 16px;
      }
      .send-email-btn {
        margin-top: 29px;
      }
      &-send-dead {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 16px;
        margin-top: 29px;
        background-color: #f5f5f5;
        border: 1px solid #d9d9d9;
        &__date {
          margin-top: 5px;
          margin-left: 2px;
        }
      }
    }
  }
}
</style>
