<template>
  <div class="header-pc-operation">
    <a-icon
      :style="{ fontSize: '24px' }"
      class="op-space--horizontal-middle"
      type="search"
      @click="jumpLink('/search')"
    />
    <div
      class="header-pc-operation__cart"
      @click="jumpLink('/cart')"
    >
      <a-icon
        :style="{ fontSize: '24px', cursor: 'pointer' }"
        type="shopping-cart"
      />
      <div
        v-show="cartNumber"
        class="header-pc-operation__cart-number"
      >{{ actualShowCartNumber }}</div>
    </div>
    <a-icon
      :style="{ fontSize: '24px', cursor: 'pointer' }"
      type="user"
      @click="goMyAccount"
    />
  </div>
</template>

<script>
import { jumpLink } from '@/utils/index'
import { mapState } from 'vuex'
export default {
  components: {},
  props: {},
  data() {
    return {}
  },

  computed: {
    ...mapState(['cartNumber']),
    actualShowCartNumber() {
      return this.cartNumber > 99 ? '99+' : this.cartNumber
    },
  },

  methods: {
    jumpLink,
    goMyAccount() {
      this.$emit('goMyAccount')
    },
  },
}
</script>
<style lang="less" scoped>
.header-pc-operation {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  color: var(--layout-header-color-text);

  &__cart {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 24px;
    cursor: pointer;
    &-number {
      position: absolute;
      top: -4px;
      left: 12px;
      padding: 2px 4px;
      font-size: 12px;
      line-height: 12px;
      color: #fff;
      text-align: center;
      background-color: #d40000;
      border-radius: 4px;
    }
  }
}
</style>
