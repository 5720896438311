<template>
  <div class="reset-password-success">
    <img
      class="success-img"
      src="https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/components/success.png"
    />
    <div class="success-test">{{ $t('common.reset.settingFinish') }}</div>
    <div class="countdown-go-login">
      <span
        class="go-login-btn"
        @click="goLogin"
      >{{ $t('common.reset.back') }}</span>
      <span>(</span>
      <a-statistic-countdown
        :value="deadline"
        format="s"
        @finish="countdownFinish"
      />
      <span>S)</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return { deadline: Date.now() + 1000 * 3 }
  },

  computed: {},

  methods: {
    countdownFinish() {
      this.goLogin()
    },
    goLogin() {
      this.$emit('goLogin')
    },
  },
}
</script>
<style lang="less" scoped>
.reset-password-success {
  .success-img {
    display: block;
    width: 80px;
    height: 80px;
    margin: 80px auto 0;
    object-fit: contain;
  }
  .success-test {
    margin: 16px auto 106px;
    text-align: center;
  }
  .countdown-go-login {
    .go-login-btn {
      cursor: pointer;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    color: #00b876;
    border: 1px solid #00b876;
    /deep/ .ant-statistic-content {
      font-size: 14px;
      color: #00b876;
    }
  }
}
</style>
