export default {
  'product.comment.writeCommentEmail': 'Your email',
  'product.detail.viewCart': 'View cart',
  'product.detail.additional': 'Additional Information',
  'product.comment.viewAll': 'View All',
  'product.comment.paginationTotal': 'Showing page $[X]-$[Y]',
  'product.comment.CustomerReview': 'Customer Review',
  'product.detail.description': 'Description',
  'product.comment.noResultsFound': 'No Results Found',
  'product.comment.writeFirstReview': 'Write your first review now！',
  'product.detail.viewAllComments': 'View all Reviews',
  'product.comment.withImages': 'With Images',
  'product.comment.writeReview': 'Write a Review',
  'product.comment.noReviewsYet': 'No Reviews Yet',
  'product.comment.averageRating': 'Average Rating',
  'product.detail.checkout': 'Check out',
  'product.comment.writeCommentFirstName': 'First name',
  'product.comment.shrink': 'Hide all',
  'product.detail.notAddCartTips': 'The products can not be bought on a preview page',
  'product.comment.writeCommentLastName': 'Last name',
  'product.commentPublish.back': 'Back',
  'product.comment.writeCommentLastNamePlaceholder': 'Please type in your last name',
  'product.comment.writeCommentPlaceholder': 'Please enter your review',
  'product.comment.readAll': 'Read all',
  'product.comment.writeCommentAddImagePlaceholder': 'Uploaded images can be up to $[X] (optional)',
  'product.comment.writeCommentEmailPlaceholder': 'Please fill in your email address',
  'product.detail.buyNow': 'Buy Now',
  'product.comment.writeCommentFirstNamePlaceholder': 'Please type in your first name',
  'product.detail.copyLink': 'Copy link',
  'product.commentPublish.rating': 'Rating',
  'product.detail.addCart': 'Add to Cart',
  'product.detail.continueShopping': 'Continue to Shop',
  'product.list.soldOut': 'Sold out',
  'product.detail.skuSelectTip': 'Please select product',
  'product.comment.writeCommentCancel': 'Cancel',
  'product.comment.writeCommentRating': 'Rating',
  'product.detail.comment': 'Reviews',
  'product.comment.AllReviews': 'All Reviews',
  'product.comment.writeCommentAddImage': 'Add image',
  'product.comment.writeCommentSubmit': 'Submit review',
  'product.detail.addCartTip': 'Successfully added to cart',
  'product.detail.shareProduct': 'Share with friends',
  'product.detail.noShareTips': 'The product status is \'draft\' so you can’t share it yet. Please update the status as \'active\'',
  'product.detail.copySuccess': 'Link copied successfully',
  'product.commentPublish.comment': 'Review',
  'product.detail.stock': 'Left',
  'product.commentPublish.release': 'Publish',
}
