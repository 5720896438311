export default {
  'common.register.emailRegistered': '邮箱已被注册',
  'common.addressForm.othersText': '其它',
  'common.addressForm.saveToNextUse': '保存此信息以供下次使用',
  'common.footer.subscribeText': '订阅我们，以获得独家折扣、优惠，以及最新的商品。',
  'common.register.passwordLengthTip': '密码要求8-16位',
  'common.register.lastName': '姓氏',
  'common.register.firstName': '名字',
  'common.login.noAccount': '没有账户',
  'common.login.email': '邮箱',
  'common.formValidate.maxLengthTip': '内容超长，不能超过',
  'common.register.emailFormatTip': '请填写正确的邮箱',
  'common.addressForm.province': '省',
  'common.forget.verificationCodePlaceholder': '输入发送到您邮箱的验证码',
  'common.addressForm.state': '州',
  'common.register.resend': '重新发送',
  'common.header.allProducts': '全部商品',
  'common.reset.passwordFormatTip': '密码要求8-16位，建议包含字母和数字',
  'common.networkTimeout': '网络不稳定，请刷新重试',
  'common.forget.email': '邮箱',
  'common.header.collection': '系列',
  'common.networkError': '网络已断开，请检查网络连接',
  'common.register.create': '创建',
  'common.register.confirmPassword': '确认密码',
  'common.header.homePage': '首页',
  'common.pagination.items': '条',
  'common.register.sendEmail': '发送验证码',
  'common.forget.resetPassword': '重置密码',
  'common.inputRequired': '请填写该字段',
  'common.pagination.total': '共',
  'common.addressForm.city': '城市',
  'common.login.incorrectPasswordTip': '密码不正确',
  'common.header.account': '账户',
  'common.reset.confirm': '确认',
  'common.register.agree': '继续即表示您同意',
  'common.forget.continue': '继续',
  'common.login': '登录',
  'common.header.search': '搜索',
  'common.register.passwordFormatTip': '密码要求8-16位，建议包含字母和数字',
  'common.login.register': '注册',
  'common.phoneFormatTip': '请填写正确的手机号',
  'common.addressForm.apartment': '公寓、套房等（选填）',
  'common.footer.nowSubscribe': '现在订阅',
  'common.forget.incorrectVerificationCodeTip': '验证码不正确',
  'common.register.password': '密码',
  'common.register.passwordTip': '8-16位，建议包含字母和数字',
  'common.register.inputRequiredTip': '请填写该字段',
  'common.footer.Policy': '政策',
  'common.login.password': '密码',
  'common.addressForm.phone': '手机号',
  'common.footer.copyright': '© 2022 Powered by Pandova',
  'common.register.haveAccount': '已有账户',
  'common.reset.confirmPasswordTip': '密码不一致',
  'common.footer.subscribeTip': '登录后即可订阅',
  'common.register.termsOfService': '服务条款',
  'common.forget.back': '返回',
  'common.reset.passwordLength': '密码要求8-16位',
  'common.register.verificationCodeTip': '输入发送到您邮箱的验证码',
  'common.footer.privacyPolicy': '隐私政策',
  'common.login.notExistEmailTip': '邮箱不存在',
  'common.reset.passwordTip': '8-16位，建议包含字母和数字',
  'common.reset.confirmPassword': '确认密码',
  'common.addressForm.country': '国家/地区',
  'common.reset.back': '返回登录',
  'common.register.privacyPolicy': '隐私政策',
  'common.addressForm.lastName': '姓氏',
  'common.login.forgetPassword': '忘记密码',
  'common.forget.tipText': '我们需要您的邮箱和验证码，来确认这是您本人的账户。',
  'common.forget.inputRequired': '请填写该字段',
  'common.register.confirmPasswordTip': '密码不一致',
  'common.footer.termsOfService': '服务条款',
  'common.reset.inputRequired': '请填写该字段',
  'common.header.shoppingCart': '购物车',
  'common.register.and': '和',
  'common.reset.passwordPlaceholder': '8-16位',
  'common.addressForm.zipCode': '邮政编码',
  'common.okay': '好的',
  'common.forget.notExistEmail': '邮箱不存在',
  'common.register.noAgreeTip': '请同意服务条款和隐私政策',
  'common.reset.currentAccount': '当前账户',
  'common.footer.subscribe': '订阅',
  'common.register.login': '登录',
  'common.register.passwordPlaceholder': '8-16位',
  'common.register.createAccount': '创建你的账户',
  'common.emailFormatTip': '请填写正确的邮箱',
  'common.register.incorrectVerificationTip': '验证码不正确',
  'common.addressForm.optional': '（选填）',
  'common.forget.verificationCode': '验证码',
  'common.register.verificationCode': '验证码',
  'common.formValidate.codeText': '字符',
  'common.addressForm.useNewAddress': '使用新地址',
  'common.reset.password': '密码',
  'common.register.email': '邮箱',
  'common.addressForm.firstName': '名字',
  'common.reset.settingFinish': '密码设置完成',
  'common.addressForm.detailAddress': '地址',
}
