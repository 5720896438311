<template>
  <div class="login-form">
    <div class="login-form__title">{{ $t('common.login') }}</div>
    <a-form-model
      ref="loginForm"
      layout="vertical"
      :model="loginFormData"
      :rules="loginFormRules"
    >
      <a-form-model-item
        :label="$t('common.login.email')"
        prop="mail"
      >
        <a-input
          v-model.trim="loginFormData.mail"
          size="large"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('common.login.password')"
        prop="password"
        style="margin-bottom: 12px"
      >
        <a-input
          v-model.trim="loginFormData.password"
          type="password"
          autocomplete="off"
          size="large"
        />
      </a-form-model-item>
    </a-form-model>
    <div
      class="forgot-password-btn"
      @click="goForgot"
    >{{ $t('common.login.forgetPassword') }}</div>
    <div class="login-btn">
      <op-button
        :loading="loginLoading"
        @click="login"
      >{{ $t('common.login') }}</op-button>
    </div>
    <div class="go-register">
      <span class="go-register-text">{{ $t('common.login.noAccount') }}</span>
      <op-link
        class="go-register-btn"
        @click="goRegister"
      >{{ $t('common.login.register') }}</op-link>
    </div>
  </div>
</template>

<script>
import { validateMail, validateRequired, validateRequiredAndMaxLength } from '@/utils/validator'
export default {
  components: {},
  props: {
    loginLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginFormData: {},
      loading: false,
    }
  },

  computed: {
    loginFormRules() {
      return {
        mail: [validateMail(), validateRequiredAndMaxLength()],
        password: [validateRequired()],
      }
    },
  },

  methods: {
    async login() {
      this.$refs.loginForm &&
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.$emit('login', this.loginFormData)
          }
        })
    },
    goRegister() {
      this.$emit('changeComponent', 'registerForm')
    },
    goForgot() {
      this.$emit('changeComponent', 'forgotForm')
    },
  },
}
</script>
<style lang="less" scoped>
.login-form {
  &__title {
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: 500;
    color: #01040d;
    text-align: center;
  }
  .forgot-password-btn {
    color: #8991a4;
    text-align: right;
    cursor: pointer;
  }
  .login-btn {
    margin: 40px auto 24px;
    .op-button {
      width: 100%;
    }
  }
  .go-register {
    text-align: right;
    &-text {
      color: #6d7b98;
    }
    &-btn {
      margin-left: 16px;
    }
  }
}
</style>
