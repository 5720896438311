<template>
  <div
    :class="componentId"
    class="op-swiper"
  >
    <swiper
      ref="opSwiper"
      :options="options"
    >
      <slot></slot>
    </swiper>
    <div
      slot="pagination"
      class="swiper-pagination"
    ></div>
    <div
      v-if="changePageButton"
      slot="button-prev"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="changePageButton"
      slot="button-next"
      class="swiper-button-next"
    ></div>
  </div>
</template>

<script>
import { setCssVars } from '@/utils/theme'
export default {
  name: 'OpSwiper',
  components: {},
  props: {
    swiperOption: {
      type: Object,
      default: () => ({}),
    },
    componentId: {
      type: String,
      default: 'component-default',
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    changePageButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Swiper: null,
      defaultSwiperOption: {
        speed: 200,
        paginationClickable: true,
        pagination: {
          el: '.' + this.componentId + ' .swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.' + this.componentId + ' .swiper-button-next',
          prevEl: '.' + this.componentId + ' .swiper-button-prev',
        },
        initialSlide: 0,
        slidesPerView: 1,
        observer: true,
        observeParents: true,
      },
    }
  },

  computed: {
    options() {
      return Object.assign(this.defaultSwiperOption, this.swiperOption)
    },
    colorInfo() {
      return this.$store?.state?.globalSettings?.colorInfo || {}
    },
  },

  watch: {
    activeIndex(val) {
      this.slideTo(val)
    },
  },

  mounted() {
    const swiperButtons = {
      'swiper-button-pre':
        'url("' +
        "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23" +
        this.getHexColor(this.getHexColor(this.colorInfo['color-primary-button-background'])) +
        "'%2F%3E%3C%2Fsvg%3E" +
        '")',
      'swiper-button-next':
        'url("' +
        "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23" +
        this.getHexColor(this.colorInfo['color-primary-button-background']) +
        "'%2F%3E%3C%2Fsvg%3E" +
        '")',
    }
    setCssVars(swiperButtons)
  },

  methods: {
    slideTo(index) {
      this.$refs?.opSwiper?.swiper.slideTo(index)
    },
    getHexColor(color) {
      if (!color) {
        return '2a334a'
      }
      if (color && !color.includes('rgb')) {
        return color
      }
      const values = color
        .replace(/rgba?\(/, '')
        .replace(/\)/, '')
        .replace(/[\s+]/g, '')
        .split(',')
      const a = parseFloat(values[3] || 1)
      const r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255)
      const g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255)
      const b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255)

      return (
        ('0' + r.toString(16)).slice(-2) +
        ('0' + g.toString(16)).slice(-2) +
        ('0' + b.toString(16)).slice(-2)
      )
    },
  },
}
</script>
<style lang="less" scoped>
.op-swiper {
  position: relative;
  width: 100%;
  .swiper-pagination {
    bottom: 8px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .swiper-button-prev {
    background-image: var(--swiper-button-pre);
    transform: scale(0.8);
  }

  .swiper-button-next {
    background-image: var(--swiper-button-next);
    transform: scale(0.8);
  }
  /deep/ .swiper-pagination-bullet-active {
    background: var(--color-primary-button-background) !important;
  }
  /deep/ .swiper-pagination-bullet {
    margin: 0 5px;
    background: #000;
  }
}

/* rtl:begin:ignore */
[dir='rtl'] .op-swiper {
  .swiper-button-next {
    transform: rotate(180deg) scale(0.8) !important;
  }
  .swiper-button-prev {
    transform: rotate(180deg) scale(0.8) !important;
  }
}

/* rtl:end:ignore */
</style>
