import antdZhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import momentZhCN from 'moment/locale/zh-cn'
import account from './zh-CN/account'
import common from './zh-CN/common'
import home from './zh-CN/home'
import payment from './zh-CN/payment'
import product from './zh-CN/product'
import search from './zh-CN/search'
import shoppingCart from './zh-CN/shopping-cart'

const components = {
  antLocale: antdZhCN,
  momentName: 'zh-cn',
  momentLocale: momentZhCN,
}

export default {
  message: '-',
  ...components,
  ...account,
  ...common,
  ...home,
  ...payment,
  ...product,
  ...search,
  ...shoppingCart,
}
