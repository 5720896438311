export default {
  'account.addressManage.defaultAddress': 'Default address',
  'account.addressManage.deleteConfirm': 'Confirm to delete?',
  'account.orderDetail.total': 'Total',
  'account.orderDetail.product': 'Product',
  'account.orderDetail.pay': 'Pay',
  'account.addressManage.addAddress': 'Add address',
  'account.tabs.logout': 'Log out',
  'account.orderList.all': 'All Orders',
  'account.orderDetail.shippingAddress': 'Shipping address',
  'account.orderDetail.price': 'Price',
  'account.orderDetail.Operate': 'Operation',
  'account.tabs.addressManage': 'Address Management',
  'account.addressManage.cancel': 'Cancel',
  'account.orderDetail.logisticsInfoTitle': 'shipment',
  'account.orderDetail.noDeliveryDetail': 'No logistics information is available',
  'account.orderList.orderNo': 'Order',
  'account.orderDetail': 'Order Details',
  'account.orderList.orderTotal': 'Total',
  'account.tabs.myOrder': 'My Order',
  'account.orderDetail.okay': 'Okay',
  'account.orderDetail.preTax': 'Before Tax',
  'account.orderDetail.shippingRate': 'Shipping',
  'account.orderList.orderDate': 'Date',
  'account.addressManage.edit': 'Edit',
  'account.orderDetail.contactEmail': 'Email',
  'account.orderDetail.orderNO': 'Order',
  'account.addressManage.allAddress': 'All addressess',
  'account.orderList.operate': 'Operation',
  'account.orderDetail.paymentTime': 'Payment time',
  'account.orderDetail.shipmentMessage': 'You’ll get shipping delivery updates by email.',
  'account.orderDetail.tax': 'Tax',
  'account.addressManage.delete': 'Delete',
  'account.orderDetail.deadlineSuffix': 'Order will be canceled automatically when expired',
  'account.orderDetail.billingAddress': 'Billing address',
  'account.orderList.noOrderTip': 'No order records',
  'account.orderDetail.discount': 'Discount',
  'account.addressManage.add': 'Save',
  'account.orderList.viewDetail': 'View Order',
  'account.orderDetail.TrackDetailText': 'View logistics information',
  'account.orderDetail.subTotal': 'Subtotal',
  'account.tabs.myAccount': 'My Account',
  'account.orderDetail.number': 'Quantity',
  'account.orderList.orderStatus': 'Order status',
  'account.orderDetail.TrackingNumber': 'Tracking Number',
  'account.orderDetail.comment': 'Review',
  'account.orderDetail.back': 'Back',
  'account.orderDetail.paidTip': 'This order has been paid. Please view the latest order information',
  'account.orderDetail.deadlinePrefix': 'Please complete payment within',
  'account.orderDetail.contactInfo': 'Contact information',
  'account.addressManage.setDefault': 'Set as default address',
  'account.orderDetail.paymentMethod': 'Payment method',
  'account.orderDetail.contactName': 'Contact Name',
  'account.orderDetail.orderTime': 'Order time',
}
