import { formatNumber, formatMoney, formatDateTime } from '@/utils/index'

const filters = { formatNumber, formatMoney, formatDateTime }

export default {
  install: Vue => {
    Object.keys(filters).forEach(key => {
      Vue.filter(key, filters[key])
    })
  },
}
