<template>
  <div class="op-spin">
    <a-spin
      v-bind="$props"
      v-on="$listeners"
    ></a-spin>
  </div>
</template>

<script>
export default {
  name: 'OpSpin',
  components: {},
  props: {},
  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>
<style lang="less" scoped>
.op-spin {
  width: 100%;
  padding: 100px 50px;
  margin-bottom: 20px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
}
</style>
