// 默认语言
export const DEFAULT_LANGUAGE = 'en-US'

// 语言类型
export const LANGUAGE_TYPE = {
  ENGLISH: 'en-US',
  ARABIC: 'ar-EG',
  CHINESE: 'zh-CN',
}

// 语言类型文案映射
export const LANGUAGE_TYPE_TEXT_MAP = {
  [LANGUAGE_TYPE.ENGLISH]: 'English',
  [LANGUAGE_TYPE.ARABIC]: 'العربية',
  [LANGUAGE_TYPE.CHINESE]: '简体中文',
}

// 语言类型文案映射列表
export const LANGUAGE_TYPE_TEXT_MAP_LIST = Object.values(LANGUAGE_TYPE).map(item => {
  return {
    label: LANGUAGE_TYPE_TEXT_MAP[item],
    value: item,
  }
})

// 主题预览模式
export const PREVIEW_MODE = {
  PREVIEW_MERCHANT: 1,
  PREVIEW_FREE: 2,
}

// 主题预览模式文案映射
export const PREVIEW_MODE_TEXT_MAP = {
  [PREVIEW_MODE.PREVIEW_MERCHANT]: '商家主题预览',
  [PREVIEW_MODE.PREVIEW_FREE]: '免费主题预览',
}

// 主题预览模式文案映射列表
export const PREVIEW_MODE_TEXT_MAP_LIST = Object.values(PREVIEW_MODE).map(item => {
  return {
    label: PREVIEW_MODE_TEXT_MAP[item],
    value: item,
  }
})

// 商品销售状态
export const SELL_STATUS = {
  SELL_OUT: 1,
  IN_STOCK: 2,
}

// 商品销售状态文案映射
export const SELL_STATUS_TEXT_MAP = {
  [SELL_STATUS.SELL_OUT]: '售罄',
  [SELL_STATUS.IN_STOCK]: '有库存',
}

// 商品销售状态文案映射列表
export const SELL_STATUS_TEXT_MAP_LIST = Object.values(SELL_STATUS).map(item => {
  return {
    label: SELL_STATUS_TEXT_MAP[item],
    value: item,
  }
})

// 邮箱校验正则
export const EMAIL_VALIDATE_REG = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
// 密码校验正则
export const PASSWORD_VALIDATE_REG = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{8,16}$/

// 地址默认类型
export const ADDRESS_DEFAULT_TYPE = {
  DEFAULT: 1,
  NOT_DEFAULT: 2,
}

// 地址默认类型文案映射
export const ADDRESS_DEFAULT_TYPE_TEXT_MAP = {
  [ADDRESS_DEFAULT_TYPE.DEFAULT]: '默认地址',
  [ADDRESS_DEFAULT_TYPE.NOT_DEFAULT]: '非默认地址',
}

// 地址默认类型文案映射列表
export const ADDRESS_DEFAULT_TYPE_TEXT_MAP_LIST = Object.values(ADDRESS_DEFAULT_TYPE).map(item => {
  return {
    label: ADDRESS_DEFAULT_TYPE_TEXT_MAP[item],
    value: item,
  }
})

// 地址类型
export const ADDRESS_TYPE = {
  BILL_ADDRESS: 1,
  POST_ADDRESS: 2,
}

// 地址类型文案映射
export const ADDRESS_TYPE_TEXT_MAP = {
  [ADDRESS_TYPE.BILL_ADDRESS]: '账单地址',
  [ADDRESS_TYPE.POST_ADDRESS]: '邮寄地址',
}

// 地址类型文案映射列表
export const ADDRESS_TYPE_TEXT_MAP_LIST = Object.values(ADDRESS_TYPE).map(item => {
  return {
    label: ADDRESS_TYPE_TEXT_MAP[item],
    value: item,
  }
})

// 是否追踪库存
export const IS_TRACK_STOCK = {
  TRACK_STOCK: 1,
  UN_TRACK_STOCK: 2,
}

// 是否追踪库存文案映射
export const IS_TRACK_STOCK_TEXT_MAP = {
  [IS_TRACK_STOCK.TRACK_STOCK]: '追踪库存',
  [IS_TRACK_STOCK.UN_TRACK_STOCK]: '不追踪库存',
}

// 是否追踪库存文案映射列表
export const IS_TRACK_STOCK_TEXT_MAP_LIST = Object.values(IS_TRACK_STOCK).map(item => {
  return {
    label: IS_TRACK_STOCK_TEXT_MAP[item],
    value: item,
  }
})

// 购物车商品是否失效
export const CART_PRODUCT_VALID = {
  VALID: 1,
  DISABLED: 2,
}

// 购物车商品是否失效文案映射
export const CART_PRODUCT_VALID_TEXT_MAP = {
  [CART_PRODUCT_VALID.VALID]: '有效',
  [CART_PRODUCT_VALID.DISABLED]: '失效',
}

// 购物车商品是否失效文案映射列表
export const CART_PRODUCT_VALID_TEXT_MAP_LIST = Object.values(CART_PRODUCT_VALID).map(item => {
  return {
    label: CART_PRODUCT_VALID_TEXT_MAP[item],
    value: item,
  }
})

// 购物车商品是否改变
export const CART_PRODUCT_CHANGE = {
  CHANGE: 1,
  NO_CHANGE: 2,
}

// 购物车商品是否改变文案映射
export const CART_PRODUCT_CHANGE_TEXT_MAP = {
  [CART_PRODUCT_CHANGE.CHANGE]: '已改变',
  [CART_PRODUCT_CHANGE.NO_CHANGE]: '未变化',
}

// 购物车商品是否改变文案映射列表
export const CART_PRODUCT_CHANGE_TEXT_MAP_LIST = Object.values(CART_PRODUCT_CHANGE).map(item => {
  return {
    label: CART_PRODUCT_CHANGE_TEXT_MAP[item],
    value: item,
  }
})

// 订单状态
export const ORDER_STATUS = {
  UNPAID: 100,
  WAIT_DELIVER: 200,
  DELIVERED: 300,
  RETURNED: 400,
  COMPLETED: 500,
  CANCELED: 600,
}

// 订单状态文案映射
export const ORDER_STATUS_TEXT_MAP = {
  [ORDER_STATUS.UNPAID]: '待付款',
  [ORDER_STATUS.WAIT_DELIVER]: '待发货',
  [ORDER_STATUS.DELIVERED]: '已发货',
  [ORDER_STATUS.RETURNED]: '已退回',
  [ORDER_STATUS.COMPLETED]: '已完成',
  [ORDER_STATUS.CANCELED]: '已取消',
}

// 订单状态文案映射列表
export const ORDER_STATUS_TEXT_MAP_LIST = Object.values(ORDER_STATUS).map(item => {
  return {
    label: ORDER_STATUS_TEXT_MAP[item],
    value: item,
  }
})

// 支付状态
export const PAY_STATUS = {
  DEFAULT: 0,
  PAYING: 1,
  PAY_SUCCESS: 2,
  PAY_FAIL: 3,
  CLOSE: 4,
}

// 支付状态文案映射
export const PAY_STATUS_TEXT_MAP = {
  [PAY_STATUS.DEFAULT]: '默认值',
  [PAY_STATUS.PAYING]: '支付中',
  [PAY_STATUS.PAY_SUCCESS]: '支付成功',
  [PAY_STATUS.PAY_FAIL]: '支付失败',
  [PAY_STATUS.CLOSE]: '已关单',
}

// 支付状态文案映射列表
export const PAY_STATUS_TEXT_MAP_LIST = Object.values(PAY_STATUS).map(item => {
  return {
    label: PAY_STATUS_TEXT_MAP[item],
    value: item,
  }
})

// 订单来源
export const ORDER_SOURCE = {
  WEB: 1,
  ANDROID: 2,
  IOS: 3,
}

// 订单来源文案映射
export const ORDER_SOURCE_TEXT_MAP = {
  [ORDER_SOURCE.WEB]: 'web端',
  [ORDER_SOURCE.ANDROID]: '安卓',
  [ORDER_SOURCE.IOS]: 'ios',
}

// 订单来源文案映射列表
export const ORDER_SOURCE_TEXT_MAP_LIST = Object.values(ORDER_SOURCE).map(item => {
  return {
    label: ORDER_SOURCE_TEXT_MAP[item],
    value: item,
  }
})
// 是、否
export const JUDGE_TYPE = {
  YES: 1,
  NO: 2,
}

// 是、否文案映射
export const JUDGE_TYPE_TEXT_MAP = {
  [JUDGE_TYPE.YES]: '是',
  [JUDGE_TYPE.NO]: '否',
}
// 对应国家类型
export const LANGUAGE_COUNTRY_TYPE = {
  NG: '+234',
  EG: '+20',
}
export const LANGUAGE_COUNTRY_FLAG = {
  [LANGUAGE_COUNTRY_TYPE.NG]:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAYAAACq26WdAAAAAXNSR0IArs4c6QAAAJNJREFUSEtjZJqc9JwBBP4z/AfT6ICR4f+XlCmMjAyM2OUZGBhefP/EpLy08h9W/QjBH4xMk5Pe/GNhEsan8H/GbLzmPP/28bXUohJRApZdHrVsNBjxhcBoAhmS+WxK8ut/zIwi9ClBRi3DE87EF8R0DsZX/5gZ8VYPVKtiGKckv/rPzMhMYWr8K7WoBK8ZDAwMTwEUksWEGyHyTwAAAABJRU5ErkJggg==',
  [LANGUAGE_COUNTRY_TYPE.EG]:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAASCAYAAACq26WdAAAAAXNSR0IArs4c6QAAAOFJREFUSEvtlD0KwkAQhd9sFBTxh4Ai2Kh4gBwgtUfwAB7As3gOsResbQRPYKNFJFUgxOBuNMlIDiBsEpLKqefN93gzDJ0tO0DFxQDFhCddLDsE0KmYhzfg/GGlU64/xjSKsgMx8lhPHsuWMTmqPBolpUfMnO8aEx8cu4Bog8QAMAZaTKWUkxuWBnuk3haiv4IwN1qgrKkQLBPGNxuN2Qn8uYOaUy1gYVga7CB6KyDxC8XY1rIHgKMrcXiAMNcM6urKIKV0aT4ehlTDuyIi5w/T3s2vxvpjXIxHXmnbOgMIry/lcXZ3eDk3twAAAABJRU5ErkJggg==',
}
// 物流方式
export const SHIPPING_TYPE = {
  LOGISTiCS: 1,
  PICK_UP: 2,
}
