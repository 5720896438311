import Vue from 'vue'

import {
  ConfigProvider,
  Input,
  Radio,
  Checkbox,
  Select,
  Form,
  Row,
  Rate,
  Col,
  Icon,
  Popover,
  Spin,
  Menu,
  Popconfirm,
  Statistic,
  Pagination,
  message,
  Empty,
  FormModel,
  Modal,
  Result,
  Dropdown,
  Upload,
  Timeline,
  Progress,
} from 'ant-design-vue'

Vue.use(ConfigProvider)
  .use(Input)
  .use(Radio)
  .use(Checkbox)
  .use(Rate)
  .use(Select)
  .use(Form)
  .use(Row)
  .use(Col)
  .use(Icon)
  .use(Popover)
  .use(Spin)
  .use(Menu)
  .use(Popconfirm)
  .use(Statistic)
  .use(Pagination)
  .use(Empty)
  .use(FormModel)
  .use(Modal)
  .use(Result)
  .use(Dropdown)
  .use(Upload)
  .use(Timeline)
  .use(Progress)

Vue.prototype.$message = message
