import Vue from 'vue'
import VueGtag from 'vue-gtag'
// import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import store from '@/store'
import router from '@/router'
import { routerMap } from '@/router/router-map'
import { DEFAULT_LANGUAGE } from '@/constants'
import { initTheme } from '@/utils/theme'
import { getShopInfo, getThemeTemplate, getCountryCode as getCountryCodeApi } from '@/api'
import { localStorage, MallAnalytics, isProduction } from '@/utils'
import localData from '../config/localThemeConfig'

const getCountryCode = async () => {
  const { data } = await getCountryCodeApi()
  // window.$sentry.setTag({ key: 'country', value: data || 'CN' })
}

/**
 * 商城路由更新，兼容原路由跳转到新路由
 */
const mapOldRouter2NewRouter = ({ path, query, next }) => {
  const newPath = routerMap[path]
  if (path === '/home') {
    next({ path: newPath, query })
    return
  }
  if (path === '/product/product-detail') {
    next({ path: `${newPath}/${query.productId}` })
    return
  }
  if (path === '/product/collection-list') {
    next({ path: `${newPath}/${query.collectionId}` })
    return
  }
  if (path === '/payment/index') {
    next({ path: newPath, query })
    return
  }

  next({ path: newPath })
}

/**
 * 设置主题，如果线上主题数据空，或请求失败，使用本地主题数据
 */
const setTheme = async () => {
  if (
    (location.pathname === '/' || location.pathname === '/home') &&
    location.search.includes('themeId') &&
    location.search.includes('mode')
  ) {
    return
  }
  try {
    const { data } = await getThemeTemplate()
    const onlineThemeData = JSON.parse((data || {}).content || '{}')
    const isHaveOnlineThemeData =
      onlineThemeData.sectionOrder && onlineThemeData.sectionOrder.length > 0
    isHaveOnlineThemeData ? initTheme(onlineThemeData) : initTheme(localData)
  } catch (err) {
    initTheme(localData)
  }
}

const setShopLang = lang => {
  store.dispatch('setLanguage', lang || DEFAULT_LANGUAGE)
}

const getOnlineShopInfo = async () => {
  const { data, code } = await getShopInfo()

  if (code === 404) {
    router.push({
      path: '/common/404',
    })
  } else {
    const shopInfo = data || localStorage.get('shopInfo', {})
    setShopInfo(shopInfo)
    shopInfo.googleId && googleAnalytic(shopInfo.googleId)
    shopInfo.facebookId && metaPixel(shopInfo.facebookId)
  }
}
const setShopInfo = shopInfo => {
  store.commit('setShopInfo', shopInfo)
  setShopLang(shopInfo.languageCode || DEFAULT_LANGUAGE)
}

const loadRouterGuard = () => {
  router.beforeEach(async (to, from, next) => {
    if (to.path === '/common/404') {
      next()
      return
    }
    if (routerMap[to.path]) {
      mapOldRouter2NewRouter({ path: to.path, query: to.query, next })
      return
    }

    const shopInfo = localStorage.get('shopInfo')
    shopInfo && shopInfo.shopId ? setShopInfo(shopInfo) : getOnlineShopInfo()
    next()
  })
  router.afterEach(to => {
    window.scrollTo(0, 0) // 跳转页面后回到顶部，避免滚动条击穿到下一页面问题
    const { title, pv } = to.meta || {}
    if (pv) {
      MallAnalytics.setCommonEventValues({ pageName: title })
      MallAnalytics.pv(pv, to.query)
    }
  })
}

const googleAnalytic = googleAnalyticId => {
  Vue.use(
    VueGtag,
    {
      config: {
        id: googleAnalyticId,
      },
    },
    router
  )
}

const metaPixel = metaPixelId => {
  // Vue.use(VueFacebookPixel, { router })
  Vue.analytics.fbq.init(metaPixelId)
}

const bootstrap = () => {
  store.commit('setUserInfo', localStorage.get('userInfo', {}))
  store.dispatch('updateCartNumber')
  getOnlineShopInfo()
  setTheme()
  loadRouterGuard()
  if (isProduction() && process.env.NODE_ENV === 'production') {
    getCountryCode()
  }
}
export default bootstrap
