export default {
  'shoppingCart.product': 'المنتج',
  'shoppingCart.total': 'المبلغ الإجمالي',
  'shoppingCart.stock': 'متبقي',
  'shoppingCart.title': 'سلة التسوق',
  'shoppingCart.quantity': 'الكمية',
  'shoppingCart.subTotal': 'المجموع الفرعي',
  'shoppingCart.overStock': 'الكمية المضافة تجاوزت كمية المخزون',
  'shoppingCart.back': 'رجوع',
  'shoppingCart.unitPrice': 'السعر',
  'shoppingCart.empty': 'عربة التسوق فارغة',
  'shoppingCart.all': 'الكل',
  'shoppingCart.changeTip': 'تم تحديث بعض معلومات المنتجات في سلة التسوق',
  'shoppingCart.checkout': 'ادفع الآن',
}
