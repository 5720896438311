import AddressForm from './address-form.vue'
import CommentContents from './comment-contents.vue'
import CommentSummary from './comment-summary.vue'
import CommentWrite from './comment-write.vue'
import OpButton from './op-button.vue'
import OpLink from './op-link.vue'
import OpSpin from './op-spin'
import QuantityInput from './quantity-input'
import OpPopup from './op-popup'
import OpMenu from './op-menu'
import OpMenuItem from './op-menu-item'
import OpSubMenu from './op-sub-menu'
import OpProduct from './op-product'
import WhatsApp from './whats-app'
import OpSwiper from './op-swiper'
import OpProductList from './op-product-list'
import OpCheckbox from './op-checkbox'
import OpPagination from './op-pagination'
import OpUpload from './op-upload'

export default [
  AddressForm,
  CommentContents,
  CommentSummary,
  CommentWrite,
  OpButton,
  OpLink,
  OpSpin,
  QuantityInput,
  OpPopup,
  OpMenu,
  OpSubMenu,
  OpMenuItem,
  OpProduct,
  WhatsApp,
  OpSwiper,
  OpProductList,
  OpCheckbox,
  OpPagination,
  OpUpload,
]
