export default {
  'shoppingCart.product': '商品',
  'shoppingCart.total': '总计',
  'shoppingCart.stock': '剩余',
  'shoppingCart.title': '购物车',
  'shoppingCart.quantity': '数量',
  'shoppingCart.subTotal': '小计',
  'shoppingCart.overStock': '加入数量超过了库存数量',
  'shoppingCart.back': '返回',
  'shoppingCart.unitPrice': '单价',
  'shoppingCart.empty': '您的购物车是空的',
  'shoppingCart.all': '全部',
  'shoppingCart.changeTip': '某些产品信息有变化，您的购物车已更新',
  'shoppingCart.checkout': '结账',
}
