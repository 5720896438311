import { METHOD, request } from '@/utils/request'

// 上传图片
export const uploadSingleImage = params => {
  return request('/saas-api/bff/v1/file/upload-image', METHOD.POST, params, {
    headers: { source: 'OSHOP' },
    timeout: 120000,
  })
}

// 注册发送邮件给客户
export const sendRegisterEmail = params => {
  return request(
    '/saas-api/customer/v1/shop-customer-login/send-register-mail',
    METHOD.POST,
    params
  )
}

// 注册是否隐藏邮件验证码输入框
export const isHideVerification = params => {
  return request('/saas-api/customer/v1/shop-customer-login/hide-verification', METHOD.GET, params)
}

// 账号注册
export const register = params => {
  return request('/saas-api/customer/v1/shop-customer-login/register', METHOD.POST, params)
}

// 登录
export const login = params => {
  return request('/saas-api/customer/v1/shop-customer-login/login', METHOD.POST, params)
}

// 忘记密码发送邮件给客户
export const sendForgotEmail = params => {
  return request('/saas-api/customer/v1/shop-customer-login/send-forgot-mail', METHOD.POST, params)
}

// 忘记密码验证码校验
export const verifyVerificationCode = params => {
  return request(
    '/saas-api/customer/v1/shop-customer-login/validate-verification-code',
    METHOD.POST,
    params
  )
}

// 重置密码
export const resetPassword = params => {
  return request('/saas-api/customer/v1/shop-customer-login/reset-password', METHOD.POST, params)
}

// 获取国家列表
export const getCountryList = params => {
  return request('/saas-api/bff/unAuth/v1/country', METHOD.GET, params)
}

// 获取州或省列表
export const getAreaList = params => {
  return request(`/saas-api/bff/unAuth/v2/${params.countryId}/area`, METHOD.GET)
}

// 获取地址列表
export const getAddressList = params => {
  return request(
    `/saas-api/customer/v1/shop-customer/query-shipping-address/${params.customerId}`,
    METHOD.GET
  )
}

// 删除地址
export const deleteAddress = params => {
  return request(
    `/saas-api/customer/v1/shop-customer/delete-shipping-address/${params.customerAddrId}`,
    METHOD.GET
  )
}

// 新增地址
export const addAddress = params => {
  return request('/saas-api/customer/v1/shop-customer/save-shipping-address', METHOD.POST, params)
}

// 更新地址
export const updateAddress = params => {
  return request('/saas-api/customer/v1/shop-customer/edit-shipping-address', METHOD.POST, params)
}

// 获取商家主题数据
export const getThemeTemplate = params => {
  return request('/saas-api/shop-theme/c/v1/theme/query-theme', METHOD.GET, params, {
    needPreprocessBusinessError: false,
  })
}

// 获取免费主题数据
export const getFreeThemeTemplate = params => {
  return request('/saas-api/shop-theme/v1/theme/query-theme-page-temp', METHOD.GET, params)
}

// 获取商品集列表
export const getCollectionList = params => {
  return request('/saas-api/shop-goods/category/oshop/v1/list-query', METHOD.POST, params)
}

// 邮件订阅商城消息
export const subscribe = params => {
  return request('/saas-api/customer/v1/shop-customer/subscribe', METHOD.POST, params)
}

// 获取店铺信息
export const getShopInfo = params => {
  return request('/saas-api/account/v1/shop-c/get-shop-info', METHOD.GET, params, {
    needPreprocessBusinessError: false,
  })
}

// 获取未登录获取公钥
export const getPublicKeyForNoToken = params => {
  return request('/saas-api/customer/v1/shop-customer-login/web-public-key', METHOD.POST, params)
}

// 根据ip获取国家
export const getCountryCode = params => {
  return request('/saas-api/bff/ip/v1/getCountryCode', METHOD.GET, params)
}

// 获取商品列表
export const getProductList = params => {
  return request('/saas-api/shop-goods/goods/oshop/v1/page-query', METHOD.POST, params)
}

// 通过商品id list获取商品列表
export const getProductListByIds = params => {
  return request('/saas-api/shop-goods/goods/oshop/v1/query/brief-info/by-ids', METHOD.POST, params)
}

// 获取商品详情
export const getProductDetail = params => {
  return request(`/saas-api/shop-goods/goods/oshop/v1/query/goods/by-id/${params.id}`, METHOD.GET)
}

// 获取商品评价概览
export const getCommentSummary = params => {
  return request('/saas-api/comment/api/v1/find/star/level', METHOD.POST, params)
}

// 获取评价列表
export const getCommentList = params => {
  return request('/saas-api/comment/api/v1/find/list', METHOD.POST, params)
}

// 发布评价
export const publishComment = params => {
  return request('/saas-api/comment/api/v1/save', METHOD.POST, params)
}

// 添加购物车
export const addShoppingCart = params => {
  return request('/saas-api/shop-order/v1/cart/add', METHOD.POST, params)
}

// 登陆合并购物车
export const mergeShoppingCart = params => {
  return request('/saas-api/shop-order/v1/cart/merge', METHOD.POST, params)
}

// 获取购物车数量
export const getShoppingCartCount = params => {
  return request('/saas-api/shop-order/v1/cart/count', METHOD.GET, params)
}

// 获取登陆购物车列表
export const getOnlineCartList = params => {
  return request('/saas-api/shop-order/v1/cart/query', METHOD.POST, params)
}

// 获取为登陆购物车列表
export const getLocalCartList = params => {
  return request('/saas-api/shop-order/v1/cart/ignore/query', METHOD.POST, params)
}

// 更新购物车列表
export const updateShoppingCartList = params => {
  return request('/saas-api/shop-order/v1/cart/update', METHOD.POST, params)
}

// 删除购物车商品
export const deleteShoppingCartProduct = params => {
  return request('/saas-api/shop-order/v1/cart/delete', METHOD.POST, params)
}

// 清除购物车
export const clearShoppingCart = params => {
  return request('/saas-api/shop-order/v1/cart/clear', METHOD.POST, params)
}

// 下单前校验购物车商品是否已经更新
export const checkCartProductIsChange = params => {
  return request('/saas-api/shop-order/v1/cart/ignore/check', METHOD.POST, params)
}

// 获取税率列表
export const getTaxRateList = params => {
  return request('/saas-api/config/api/v1/tax/rate/find/all', METHOD.POST, params)
}

// 获取运送方式列表
export const getShippingList = params => {
  return request('/saas-api/config/v1/shipping/record/query', METHOD.GET, params)
}

// 计算订单总额
export const calculateCost = params => {
  return request('/saas-api/shop-order/v1/customer/order/calculate', METHOD.POST, params)
}

// 获取折扣码信息
export const getDiscountCodeInfo = params => {
  return request(
    '/saas-api/discount/v1/general-discount-management/apply-use-general-discount',
    METHOD.POST,
    params
  )
}

// 新订单下单支付
export const checkoutOrder = params => {
  return request('/saas-api/shop-order/v1/customer/order/checkout', METHOD.POST, params, {
    needPreprocessBusinessError: false,
  })
}

// 未支付的订单支付
export const checkoutUnPayedOrder = params => {
  return request('/saas-api/shop-order/v1/customer/order/pay', METHOD.GET, params)
}

//  弃单未支付的订单支付
export const checkoutAbandonedOrder = params => {
  return request('/saas-api/shop-order/v1/customer/order/pay/abandoned', METHOD.GET, params)
}

// 获取订单列表
export const getOrderList = params => {
  return request('/saas-api/shop-order/v1/customer/login/order/list', METHOD.POST, params)
}

// 获取订单详情
export const getOrderDetail = params => {
  return request('/saas-api/shop-order/v1/customer/order/detail', METHOD.GET, params)
}

// 通过支付链接付款获取订单详情
export const getPaymentDetail = params => {
  return request('/saas-api/shop-order/v1/customer/order/detail', METHOD.GET, params, {
    needPreprocessBusinessError: false,
  })
}

// 获取订单评价商品信息
export const getOrderProductInfo = params => {
  return request('/saas-api/shop-order/v1/customer/order/item', METHOD.GET, params)
}

// 获取订单评价商品信息
export const cancelOrder = params => {
  return request('/saas-api/shop-order/v1/customer/order/cancel', METHOD.GET, params)
}

// 根据运单号 获取物流详情
export const getDeliveryDetail = params => {
  return request(
    '/saas-api/shop-order/v1/customer/order/query/shippers/track',
    METHOD.POST,
    params,
    {
      needPreprocessBusinessError: false,
    }
  )
}
// 查询门店下到店自取配置信息
export const checkIfOpenPickUP = params => {
  return request('/saas-api/config/v1/shipping/schema/query/take/info', METHOD.GET, params)
}
// 查询账号可用支付方式
export const getPayMethod = params => {
  // return request('/saas-api/account/v1/shop-c/valid-pay-method', METHOD.GET, params)
  return request('/saas-api/bff/v1/payment/shop-c/valid-pay-method', METHOD.GET, params)
}

// 获取自定义页面信息
export const getCustomPageDetails = params => {
  return request('/saas-api/shop-theme/c/v1/page/get-page-by-ids', METHOD.POST, params)
}

// 获取商品评价列表 - 分页
export const getProductCommentList = params => {
  // goodsId number 必须 商品id（必填）
  // type number 必须 评价查询类型：1:ALL 2:with-img（非必填，默认1）
  return request('/saas-api/comment/c-comment/v1/comment/list', METHOD.POST, params)
}

// 获取商品评价统计数据（分数和个数）
export const getProductCommentStarStatistics = params => {
  // goodsId number 必须 商品id（必填）
  return request('/saas-api/comment/c-comment/v1/comment/stat', METHOD.POST, params)
}

// 新增商品评价 - 未登录状态
export const addProductCommentByVisitor = params => {
  // goodsId number 必须 商品id（必填）mock: 321
  // firstName string 必须 名字（必填） mock: zhansheng
  // lastName string 必须 姓氏（必填） mock: wei
  // mail string 必须 邮箱 mock: 60317466@qq.com
  // starLevel number 必须 星级(必填1-5) mock: 1
  // commentContent string 必须 评价内容(非必填)
  // commentImg string [] 必须 评价图片(非必填) ,String
  return request('/saas-api/comment/c-comment/v1/save-comment', METHOD.POST, params)
}

// 新增商品评价 - 已登陆状态
export const addProductCommentByLogin = params => {
  // goodsId number 必须 商品id（必填）mock: 321
  // starLevel number 必须 星级(必填1-5) mock: 1
  // commentContent string 必须 评价内容(非必填) mock: 这个东西真哇塞
  // commentImg string [] 必须 评价图片(非必填) ,String
  return request('/saas-api/comment/c-comment/v1/save-comment-login', METHOD.POST, params)
}
