<template>
  <div class="comment-content">
    <div>哈哈哈哈哈哈</div>
    <div class="comment-content__header">
      <div class="comment-content__header__left">
        <div class="comment-content__header__left__person-name">
          {{ `${commentInfo.consumerFirstName}${commentInfo.consumerLastName}` }}
        </div>
        <div class="comment-content__header__left__date">
          {{ commentInfo.ctime | formatDateTime }}
        </div>
      </div>
      <div class="comment-content__header__right">
        <a-rate v-model="commentInfo.starLevel" disabled />
        <div class="comment-content__header__right__sku">{{ commentInfo.goodsStyle }}</div>
      </div>
    </div>
    <div class="comment-content__text">
      <div
        ref="comment"
        class="comment-content__text__content"
        :class="[showExpandBtn ? 'comment-content__text__content--ellipsis' : '']"
      >
        {{ commentInfo.commentContent }}
        <span
          v-show="!showExpandBtn && isMoreTwoLine"
          class="comment-content__text__content__pick-up-btn"
          @click="pickUp"
        >
          {{ $t('product.comment.shrink') }}
        </span>
      </div>
      <div v-show="showExpandBtn" class="comment-content__text__expand-btn" @click="expand">
        <span>...</span>
        <span class="comment-content__text__expand-btn__text">
          {{ $t('product.comment.readAll') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentContents',
  props: {
    commentInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isMoreTwoLine: false,
      showExpandBtn: false,
    }
  },

  computed: {},

  mounted() {
    this.isShowExpand()
    window.addEventListener('resize', this.isShowExpand)
  },

  destroyed() {
    window.removeEventListener('resize', this.isShowExpand)
  },

  methods: {
    isShowExpand() {
      if (this.$refs.comment.clientHeight > 56) {
        this.isMoreTwoLine = true
        this.showExpandBtn = true
      } else {
        this.isMoreTwoLine = false
        this.showExpandBtn = false
      }
    },
    expand() {
      this.showExpandBtn = false
    },
    pickUp() {
      this.showExpandBtn = true
    },
  },
}
</script>
<style lang="less" scoped>
.comment-content {
  margin-bottom: 40px;
  &__header {
    display: flex;
    font-size: 14px;
    line-height: 24px;
    &__left {
      margin-right: 80px;
      &__person-name {
        color: #181e2d;
      }
      &__date {
        color: #9daac2;
      }
    }
    &__right {
      &__sku {
        color: #9daac2;
      }
    }
  }
  &__text {
    position: relative;
    margin-top: 24px;
    font-size: 16px;
    line-height: 28px;
    &__content {
      color: #47516a;
      &--ellipsis {
        display: -webkit-box;
        overflow: hidden;
        font-weight: 400;
        color: #47516a;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &__pick-up-btn {
        margin-left: 8px;
        color: var(--color-text);
        cursor: pointer;
      }
    }

    &__expand-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 10;
      padding-left: 16px;
      cursor: pointer;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 20%
      );
      &__text {
        margin-left: 4px;
        color: var(--color-text);
      }
    }
  }
}
</style>
