export default {
  'product.comment.writeCommentEmail': 'البريد الإلكتروني',
  'product.detail.viewCart': 'سلة التسوق',
  'product.detail.additional': 'معلومات إضافية',
  'product.comment.viewAll': 'عرض الكل',
  'product.comment.paginationTotal': 'عرض $[X]-$[Y] من أصل $[Z]',
  'product.comment.CustomerReview': 'تعليقات العملاء',
  'product.detail.description': 'الوصف',
  'product.comment.noResultsFound': 'لم يتم العثور على نتائج',
  'product.comment.writeFirstReview': 'اكتب تعليقك الأول الآن!',
  'product.detail.viewAllComments': 'عرض كل التعليقات',
  'product.comment.withImages': 'تعليقات بصور',
  'product.comment.writeReview': 'اكتب تعليقًا',
  'product.comment.noReviewsYet': 'لا توجد تعليقات',
  'product.comment.averageRating': 'معدل التقييم',
  'product.detail.checkout': 'ادفع الآن',
  'product.comment.writeCommentFirstName': 'الاسم الأول',
  'product.comment.shrink': 'اخفاء الكل ',
  'product.detail.notAddCartTips': 'لا يمكن شراء المنتجات في صفحة المعاينة',
  'product.comment.writeCommentLastName': 'اسم العائلة',
  'product.commentPublish.back': 'رجوع',
  'product.comment.writeCommentLastNamePlaceholder': 'يرجى إدخال اسم العائلة',
  'product.comment.writeCommentPlaceholder': ' يرجى كتابة تعليق',
  'product.comment.readAll': 'قراءة كل التعليقات',
  'product.comment.writeCommentAddImagePlaceholder': 'رفع الصور (اختياري) يمكن رفع ما يصل إلى $[X] صورة ',
  'product.comment.writeCommentEmailPlaceholder': 'يرجى إدخال بريدك الإلكتروني',
  'product.detail.buyNow': 'اشتري الآن',
  'product.comment.writeCommentFirstNamePlaceholder': 'يرجى إدخال الاسم الأول',
  'product.detail.copyLink': 'نسخ الرابط',
  'product.commentPublish.rating': 'التقييم',
  'product.detail.addCart': 'إضافة لسلة التسوق',
  'product.detail.continueShopping': 'تابع التسوق',
  'product.list.soldOut': 'نفدت الكمية',
  'product.detail.skuSelectTip': 'الرجاء تحديد مواصفات المنتج',
  'product.comment.writeCommentCancel': 'إلغاء',
  'product.comment.writeCommentRating': 'التقييم',
  'product.detail.comment': 'تعليقات',
  'product.comment.AllReviews': 'كل التعليقات',
  'product.comment.writeCommentAddImage': 'إضافة صورة',
  'product.comment.writeCommentSubmit': 'إرسال التعليق',
  'product.detail.addCartTip': 'تم الإضافة إلى سلة التسوق بنجاح',
  'product.detail.shareProduct': 'مشاركة المنتج مع صديق',
  'product.detail.noShareTips': 'المنتج في حالة \'مسودة\'، لا يمكن مشاركة المنتج حاليا. يرجى تحديث حالة المنتج إلى \'مفعل\'',
  'product.detail.copySuccess': 'تم نسخ الرابط بنجاح',
  'product.commentPublish.comment': 'التعليق',
  'product.detail.stock': 'متبقي',
  'product.commentPublish.release': 'إرسال',
}
