<template>
  <div class="op-menu">
    <template v-for="item in menu">
      <op-menu-item
        v-if="!item.children"
        :key="item.key"
        :menu-item="item"
        @clickMenu="clickMenu"
      ></op-menu-item>
      <op-sub-menu
        v-else
        :key="item.key"
        :sub-menu="item"
        @clickMenu="clickMenu"
      ></op-sub-menu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OpMenu',
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },

  computed: {},

  methods: {
    clickMenu(menuItem) {
      this.$emit('clickMenu', menuItem)
    },
  },
}
</script>
<style lang="less" scoped>
.op-menu {
  padding: 8px 16px;
  background: #fff;
}
</style>
