<template>
  <div class="header-pc-menu">
    <a-menu
      mode="horizontal"
      :selected-keys="currentMenu"
    >
      <template v-for="(menu, index) in menuList">
        <a-menu-item
          v-if="!menu.children"
          :key="index"
        >
          <router-link :to="menu.path">{{ menu.title }}</router-link>
        </a-menu-item>
        <a-sub-menu
          v-else
          :key="menu.key"
          popup-class-name="header-pc-menu__sub-menu"
        >
          <span slot="title">{{ menu.title }}</span>
          <a-menu-item
            v-for="(subMenu, subMenuIndex) in menu.children"
            :key="subMenuIndex"
          >
            <!-- :key="subMenu.key" -->
            <router-link :to="{ path: subMenu.path, query: subMenu.query }">{{ subMenu.title }}</router-link>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    currentMenu: {
      type: Array,
      default: () => ['home'],
    },
  },
  data() {
    return {}
  },

  computed: {},

  methods: {},
}
</script>
<style lang="less" scoped>
.header-pc-menu {
  margin-left: 96px;
  /deep/ .ant-menu {
    color: var(--ant-color-text);
    background-color: unset;
  }

  /deep/ .ant-menu-item {
    padding: 0 0;
    margin-right: 32px;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  /deep/ .ant-menu-item:hover {
    color: var(--ant-color-text);
    border-bottom: 2px solid var(--ant-color-text);
  }
  /deep/ .ant-menu-horizontal {
    border: none;
  }
  /deep/ .ant-menu-horizontal > .ant-menu-item > a {
    color: var(--ant-color-text) !important;
  }
  /deep/ .ant-menu-submenu {
    margin-right: 32px;
  }
  .ant-menu-horizontal > .ant-menu-submenu-open {
    color: var(--ant-color-text) !important;
    border-bottom: 2px solid var(--ant-color-text) !important;
  }
  /deep/ .ant-menu-horizontal > .ant-menu-item-selected {
    color: var(--ant-color-text);
    border-bottom: 2px solid var(--ant-color-text);
  }
  /deep/ .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: var(--ant-color-text);
    border-bottom: 2px solid var(--ant-color-text);
  }
  /deep/ .ant-menu-submenu:hover {
    border-bottom: 2px solid var(--ant-color-text) !important;
  }
  /deep/ .ant-menu-submenu-title {
    padding: 0 0;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  /deep/ .ant-menu-submenu-title:hover {
    color: var(--ant-color-text, #fff);
  }
}
</style>
<style lang="less">
.header-pc-menu__sub-menu {
  background-color: var(--ant-sub-menu-color-bg, #fff) !important;
  .ant-menu {
    background-color: var(--ant-sub-menu-color-bg, #fff) !important;
  }
  .ant-menu-item {
    color: var(--ant-sub-menu-color-text, #2a334a);
    background-color: unset !important;
  }
  .ant-menu-item:hover {
    color: var(--ant-sub-menu-color-text, #2a334a);
  }
  .ant-menu-item-selected {
    color: var(--ant-sub-menu-color-text, #2a334a);
    background-color: var(--ant-sub-menu-color-bg, #fff) !important;
  }
  a {
    color: var(--ant-sub-menu-color-text, #fff) !important;
  }
}
</style>
