<template>
  <div class="header-mobile-menu">
    <div
      class="header-mobile-menu__popup"
      :class="[mobileMenuIsShow ? 'header-mobile-menu__popup--active' : '']"
    >
      <div class="header-mobile-menu__popup__header">
        <a-icon
          :style="{ fontSize: '24px', cursor: 'pointer' }"
          type="close"
          @click="closeMobileMenu"
        />
      </div>
      <op-menu
        class="header-mobile-menu__popup__content"
        :menu="menuList"
        @clickMenu="clickMenu"
      ></op-menu>
    </div>
    <div
      v-if="mobileMenuIsShow"
      class="header-mobile-menu__popup-mask"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    mobileMenuIsShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },

  computed: {},

  methods: {
    clickMenu(menuItem) {
      this.closeMobileMenu()
    },
    closeMobileMenu() {
      this.$emit('closeMobileMenu')
    },
  },
}
</script>
<style lang="less" scoped>
.header-mobile-menu {
  &__popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 300px;
    height: 100vh;
    overflow: hidden;
    color: var(--mobile-menu-color-text);
    background: var(--mobile-menu-color-bg, #fff);
    transition: all 0.2s ease-in-out 0s;
    transform: translateX(-300px);
    &__header {
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      border-bottom: 1px solid #f4f6f8;
    }
    &__content {
      height: calc(100vh - 57px);
      overflow: auto;
      color: var(--mobile-menu-color-text);
      background: var(--mobile-menu-color-bg);
      /deep/ .op-menu-item a {
        color: var(--mobile-menu-color-text);
      }
    }
  }
  .header-mobile-menu__popup--active {
    transform: translateX(0);
  }
  .header-mobile-menu__popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100%;
    background: rgba(26, 26, 27, 0.3);
    opacity: 0.3;
  }
}
</style>
