<!-- 公告栏 -->
<template>
  <div class="announcement-bar">
    <template v-for="item in sectionJson && sectionJson.blockOrder">
      <div
        v-if="!sectionJson.blocks[item].disabled"
        :key="item"
        :style="buildStyle(sectionJson.blocks[item].settings)"
        class="announcement-bar__item"
        @click="jumpPage(sectionJson.blocks[item].settings.announcementLink)"
      >{{ sectionJson.blocks[item].settings.announcementText }}</div>
    </template>
  </div>
</template>

<script>
import { jumpLink } from '@/utils/index'
export default {
  name: 'AnnouncementsBar',
  components: {},
  props: {
    sectionJson: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },

  computed: {},

  methods: {
    jumpPage(jumpInfo) {
      const { path, query } = jumpInfo || {}
      jumpLink(path, query)
    },
    buildStyle(styleJson) {
      return {
        color: styleJson.colorText,
        backgroundColor: styleJson.colorBg,
      }
    },
  },
}
</script>
<style lang="less" scoped>
.announcement-bar {
  width: 100%;

  &__item {
    padding: 16px 0;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #f4f6f8;
  }
}
</style>
