<template>
  <div
    class="op-popup"
    @mouseenter="hoverShow"
    @mouseleave="hoverHide"
  >
    <div @click="clickEvent">
      <slot></slot>
    </div>
    <div class="op-popup__content">
      <div class="op-popup__content-body">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { localStorage } from '@/utils'
export default {
  name: 'OpPopup',
  components: {},
  props: {
    trigger: {
      type: String,
      default: 'click',
    },
    position: {
      type: Object,
      default: () => ({
        right: -1,
        top: 10,
      }),
    },
  },
  data() {
    return {}
  },

  computed: {},
  mounted() {
    window.addEventListener('click', this.isContainElement)
    const dom = this.$el.querySelector('.op-popup__content')
    dom.addEventListener('click', this.hidePopup)
  },

  destroyed() {
    window.removeEventListener('click', this.isContainElement)
    const dom = this.$el.querySelector('.op-popup__content')
    dom.removeEventListener('click', this.hidePopup)
  },

  methods: {
    isContainElement(e) {
      if (this.$el && !this.$el.contains(e.target)) {
        this.hidePopup()
      }
    },
    showPopup(client = {}) {
      const el = this.$el
      const dom = el.querySelector('.op-popup__content')
      dom.style.top = client.clientHeight + this.position.top + 'px'
      if (this.position.right) {
        if (localStorage.get('lang') === 'ar-EG') {
          dom.style.left = this.position.right + 'px'
          dom.style.right = 'unset'
        } else {
          dom.style.right = this.position.right + 'px'
          dom.style.left = 'unset'
        }
      }
      dom.style.display = 'block'
    },
    hidePopup() {
      const el = this.$el
      const dom = el.querySelector('.op-popup__content')
      dom.style.display = 'none'
    },
    calcShowPosition(e) {
      const client = {
        clientHeight: e.target.clientHeight,
        clientWidth: e.target.clientWidth,
        clientLeft: e.target.clientLeft,
        clientTop: e.target.clientTop,
      }
      return client
    },
    hoverShow(e) {
      if (this.trigger !== 'hover') {
        return
      }
      this.showPopup(this.calcShowPosition(e))
    },
    hoverHide(e) {
      if (this.trigger !== 'hover') {
        return
      }
      this.hidePopup()
    },
    clickEvent(e) {
      if (this.trigger !== 'click') {
        return
      }
      const el = this.$el
      const dom = el.querySelector('.op-popup__content')
      if (dom.style.display === 'none') {
        this.clickShow(e)
      } else {
        this.clickHide()
      }
    },
    clickShow(e) {
      this.showPopup(this.calcShowPosition(e))
    },
    clickHide(e) {
      this.hidePopup()
    },
  },
}
</script>
<style lang="less" scoped>
.op-popup {
  position: relative;
  &__content {
    position: absolute;
    z-index: 999;
    display: none;
    box-shadow: 0 2px 8px #00000026;
    &-body {
      padding: 8px;
      padding-top: 4px;
      color: #000;
      background: #fff;
      border-radius: 4px;
    }
  }
}
</style>
