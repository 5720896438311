<template>
  <div class="login-dialog">
    <div class="login-dialog-body">
      <a-icon
        class="close-login-dialog"
        :style="{ fontSize: '18px', color: '#8991a4' }"
        type="close"
        @click="closeDialog"
      />
      <login-form
        v-show="componentName === 'loginForm'"
        :login-loading="loginLoading"
        @login="login"
        @changeComponent="changeComponent"
      ></login-form>
      <register-form
        v-show="componentName === 'registerForm'"
        @login="login"
        @changeComponent="changeComponent"
      ></register-form>
      <forgot-password-form
        v-show="componentName === 'forgotForm'"
        @changeComponent="changeComponent"
      ></forgot-password-form>
    </div>
  </div>
</template>

<script>
import LoginForm from './login-form.vue'
import RegisterForm from './register-form.vue'
import ForgotPasswordForm from './forgot-password-form.vue'
import { login, mergeShoppingCart, getPublicKeyForNoToken } from '@/api'
import rsaUtil from '@/utils/rsa-util'
import { localStorage } from '@/utils'
export default {
  components: {
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
  },
  props: {
    currentForm: {
      type: String,
      default: 'LoginForm',
    },
    returnTo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      componentName: 'forgotForm',
      loginLoading: false,
    }
  },

  computed: {},

  created() {
    this.componentName = this.currentForm
  },

  methods: {
    async login(accountInfo) {
      this.loginLoading = true
      try {
        const publicKey = await getPublicKeyForNoToken({ mail: accountInfo.mail })
        const password = rsaUtil.encrypt(accountInfo.password, publicKey && publicKey.data)
        const { data } = await login({ ...accountInfo, password })
        const userInfo = data || {}
        // window.$sentry.setUser(userInfo)
        localStorage.set('accessToken', userInfo.token)
        this.$store.commit('setUserInfo', userInfo)
        this.addLocalCart2Online()
        this.returnToPage()
        this.closeDialog()
      } finally {
        this.loginLoading = false
      }
    },
    async addLocalCart2Online() {
      try {
        const localCartList = localStorage.get('shoppingCart', [])
        if (localCartList && localCartList.length > 0) {
          const params = localCartList.map(item => {
            return {
              goodsId: item.goodsId,
              goodsName: item.goodsName,
              skuId: item.skuId,
              skuNum: item.skuNum,
              salePrice: item.salePrice,
              saleTotalPrice: item.saleTotalPrice,
              imgUrl: item.imgUrl,
              orderItemSpecsParamDTOList: item.orderItemSpecsParamDTOList,
            }
          })
          await mergeShoppingCart(params)
          localStorage.remove('shoppingCart')
        }
      } finally {
        this.$store.dispatch('updateCartNumber')
      }
    },
    changeComponent(componentName) {
      this.componentName = componentName
    },
    closeDialog() {
      this.$store.commit('closeLoginDialog')
    },
    returnToPage() {
      if (this.returnTo) {
        this.$router.push({
          path: this.returnTo,
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.login-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: rgba(26, 26, 27, 0.3);
  &-body {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 200;
    width: 560px;
    max-width: 88%;
    max-height: 96vh;
    padding: 40px;
    overflow: auto;
    background: #fff;
    transform: translate3d(-50%, -50%, 0);
    .close-login-dialog {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }
}
@media (max-width: 576px) {
  .login-dialog {
    &-body {
      padding: 24px 16px;
    }
  }
}
</style>
