<template>
  <div class="pagination-container">
    <a-pagination
      :total="total"
      :show-total="(total, range) => formatTotal(range[0], range[1], total)"
      :current="currentSize"
      :page-size="pageSize"
      :page-size-options="pageSizeOptions"
      show-size-changer
      @change="handleSizeChange"
      @showSizeChange="handleSizeOptionsChange"
    />
  </div>
</template>
<script>
export default {
  name: 'OpPagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    currentSize: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizeOptions: {
      type: Array,
      default: () => ['10', '20', '30', '40']
    }
  },
  methods: {
    formatTotal(pageSizeStart, pageSizeEnd, total) {
      return this.$t('product.comment.paginationTotal').replace('$[X]', pageSizeStart).replace('$[Y]', pageSizeEnd).replace('$[Z]', total)
    },
    handleSizeChange(page, pageSize) {
      this.$emit('change', page, pageSize)
    },
    handleSizeOptionsChange(current, size) {
      this.$emit('change', 1, size)
    }
  }
}
</script>
<style lang="less" scoped>
.pagination-container {
  padding: 32px 0;
  /deep/.ant-pagination {
    position: relative;
    .ant-pagination-total-text {
      position: absolute;
      right: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .pagination-container {
    /deep/.ant-pagination {
      .ant-pagination-total-text {
        right: auto;
        left: 0;
        top: 50px;
      }
    }
  }
}
</style>
