<template>
  <div class="op-quantity">
    <div
      class="op-quantity__minus"
      :class="[number <= 1 ? 'quantity-btn-disabled' : 'quantity-btn']"
      @click="minus"
    >
      <a-icon
        :style="{ fontSize: '12px' }"
        type="minus"
        :disabled="true"
      />
    </div>
    <input
      v-model="number"
      class="op-quantity__input"
      @input="inputNumber"
    />
    <div
      class="op-quantity__plus"
      :class="[disabledPlus ? 'quantity-btn-disabled' : 'quantity-btn']"
      @click="plus"
    >
      <a-icon
        :style="{ fontSize: '12px' }"
        type="plus"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { IS_TRACK_STOCK } from '@/constants'
export default {
  name: 'QuantityInput',
  components: {},
  props: {
    value: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    listIndex: {
      type: Number,
      default: 0,
    },
    isTrackStock: {
      type: Number,
      default: IS_TRACK_STOCK.TRACK_STOCK,
    },
  },
  data() {
    return {
      number: 1,
      stockTip: '',
    }
  },

  computed: {
    disabledPlus() {
      return this.isTrackStock === IS_TRACK_STOCK.TRACK_STOCK && this.number >= this.maxValue
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.number = val
      },
    },
    number(val) {
      if (this.disabledPlus) {
        this.number = this.maxValue
        this.$emit('input', this.number)
      }
    },
  },

  methods: {
    minus() {
      if (this.number <= 1) {
        return
      }
      this.number--
      this.$emit('input', this.number)
      this.emitNumberUpdate()
    },
    plus() {
      if (this.disabledPlus) {
        return
      }
      this.number++
      this.$emit('input', this.number)
      this.emitNumberUpdate()
    },
    inputNumber() {
      this.number = +this.number.toString().replace(/\D/g, '')
      this.$emit('input', this.number)
      this.emitNumberUpdate()
    },
    blur() {
      this.$emit('input', this.number)
      this.emitNumberUpdate()
    },
    emitNumberUpdate: debounce(function () {
      this.$emit('updateNumber', { number: this.number, index: this.listIndex })
    }, 500),
  },
}
</script>
<style lang="less" scoped>
.op-quantity {
  position: relative;
  display: flex;
  align-items: center;
  width: 105px;
  height: 40px;
  padding: 0 4px;
  overflow: hidden;
  user-select: none;
  background: rgba(249, 250, 251, 1);
  border: 1px solid #dcdee0;
  border-radius: 4px;
  &__minus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 100%;
  }
  &__plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 100%;
  }
  &__input {
    flex: 1;
    width: 50px;
    font-size: 14px;
    color: rgba(24, 30, 45, 1);
    text-align: center;
    background-color: rgba(249, 250, 251, 1);
    border: 0;
    outline: none;
  }
  .quantity-btn {
    cursor: pointer;
  }
  .quantity-btn-disabled {
    color: #c8c9cc;
    cursor: not-allowed;
  }
}
</style>
