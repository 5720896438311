export default {
  'common.register.emailRegistered': 'This email has already been registered',
  'common.addressForm.othersText': 'Others',
  'common.addressForm.saveToNextUse': 'Save the information for next-time use',
  'common.footer.subscribeText': 'Subscribe to get exclusive discounts and latest products',
  'common.register.passwordLengthTip': 'Password shold be 8 to 16 characters in length',
  'common.register.lastName': 'Last name',
  'common.register.firstName': 'First name',
  'common.login.noAccount': 'Don’t have an account',
  'common.login.email': 'Email',
  'common.formValidate.maxLengthTip': 'The content is too long and cannot exceed',
  'common.register.emailFormatTip': 'Please enter the correct email ',
  'common.addressForm.province': 'Province',
  'common.forget.verificationCodePlaceholder': 'Please fill in the verification code sent to your email',
  'common.addressForm.state': 'State',
  'common.register.resend': 'Resend',
  'common.header.allProducts': 'All products',
  'common.reset.passwordFormatTip': 'Password must be 8-16 digits and recommended to contain letters and numbers',
  'common.networkTimeout': 'Unstable network connection. Please refresh and try again later.',
  'common.forget.email': 'Email',
  'common.header.collection': 'Collections',
  'common.networkError': 'Network disconnected. Please check network',
  'common.register.create': 'Create',
  'common.register.confirmPassword': 'Confirm password',
  'common.header.homePage': 'Home',
  'common.pagination.items': 'Items',
  'common.register.sendEmail': 'Send code',
  'common.forget.resetPassword': 'Reset password',
  'common.inputRequired': 'Please fill in this field',
  'common.pagination.total': 'Total',
  'common.addressForm.city': 'City',
  'common.login.incorrectPasswordTip': 'Incorrect password',
  'common.header.account': 'Account',
  'common.reset.confirm': 'Confirm',
  'common.register.agree': 'By creating a store, you agree to our',
  'common.forget.continue': 'Continue',
  'common.login': 'Log in',
  'common.header.search': 'Search',
  'common.register.passwordFormatTip': 'Password must be 8-16 digits and recommended to contain letters and numbers',
  'common.login.register': 'Sign up',
  'common.phoneFormatTip': 'Please enter the phone number',
  'common.addressForm.apartment': 'Apartment, suite, etc. (optional)',
  'common.footer.nowSubscribe': 'Subscribe Now',
  'common.forget.incorrectVerificationCodeTip': 'Incorrect verification code',
  'common.register.password': 'Password',
  'common.register.passwordTip': '8-16 digits and recommended to contain letters and numbers',
  'common.register.inputRequiredTip': 'Please fill in this field',
  'common.footer.Policy': 'Policy',
  'common.login.password': 'Password',
  'common.addressForm.phone': 'Phone Number',
  'common.footer.copyright': '© 2022 Powered by Pandova',
  'common.register.haveAccount': 'Already have an account?',
  'common.reset.confirmPasswordTip': 'Inconsistent password',
  'common.footer.subscribeTip': 'Log In to Subscribe',
  'common.register.termsOfService': 'Terms of Service',
  'common.forget.back': 'Back',
  'common.reset.passwordLength': 'Password shoule be 8 to 16 characters in length',
  'common.register.verificationCodeTip': 'Please fill in the verification code sent to your email',
  'common.footer.privacyPolicy': 'Privacy Policy',
  'common.login.notExistEmailTip': 'Email does not exist',
  'common.reset.passwordTip': '8-16 digits and recommended to contain letters and numbers',
  'common.reset.confirmPassword': 'Confirm the password',
  'common.addressForm.country': 'Country/Region',
  'common.reset.back': 'Back to log in',
  'common.register.privacyPolicy': 'Privacy Policy',
  'common.addressForm.lastName': 'Last Name',
  'common.login.forgetPassword': 'Forget password?',
  'common.forget.tipText': 'We need your email and verification code to make sure this is your account',
  'common.forget.inputRequired': 'Please fill in this field',
  'common.register.confirmPasswordTip': 'Inconsistent password',
  'common.footer.termsOfService': 'Terms of Service',
  'common.reset.inputRequired': 'Please fill in this field',
  'common.header.shoppingCart': 'Cart',
  'common.register.and': 'and',
  'common.reset.passwordPlaceholder': '8 to 16 characters in length',
  'common.addressForm.zipCode': 'ZIP Code',
  'common.okay': 'Okay',
  'common.forget.notExistEmail': 'Email does not exist',
  'common.register.noAgreeTip': 'Please agree to the Terms of Service and Privacy Policy',
  'common.reset.currentAccount': 'Current account',
  'common.footer.subscribe': 'Subscribe',
  'common.register.login': 'Log in',
  'common.register.passwordPlaceholder': '8 to 16 characters in length',
  'common.register.createAccount': 'Create your account',
  'common.emailFormatTip': 'Please enter the correct email ',
  'common.register.incorrectVerificationTip': 'Incorrect verification code',
  'common.addressForm.optional': '(Optional)\t',
  'common.forget.verificationCode': 'Verification code',
  'common.register.verificationCode': 'Verification code',
  'common.formValidate.codeText': 'characters',
  'common.addressForm.useNewAddress': 'Use a new address',
  'common.reset.password': 'Password',
  'common.register.email': 'Email',
  'common.addressForm.firstName': 'First Name',
  'common.reset.settingFinish': 'Password setting finished',
  'common.addressForm.detailAddress': 'Address',
}
