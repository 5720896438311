export default {
  'payment.checkout.payNow': '现在付款',
  'payment.contactInformation.signEmail': '通过电子邮件向我发送新闻和优惠',
  'payment.changedLink': '订单信息已变更，请查看最新订单信息',
  'payment.contactInformation.email': '邮箱',
  'payment.orderSummary.tax': '税',
  'payment.orderSummary.cancel': '移除',
  'payment.disabledLink.back': '返回首页',
  'payment.success.viewOrder': '查看订单',
  'payment.shipping.pickUpAddress': '取件地点',
  'payment.navigation.payMethod': '付款',
  'payment.shipping.pickUPInStore': '到店自取',
  'payment.orderSummary.total': '总计',
  'payment.disabledLink': '抱歉，订单已失效',
  'payment.navigation.shoppingCart': '购物车',
  'payment.contactInformation.haveAccount': '已有账户',
  'payment.shipping.pleaseSelect': '请选择运输方式',
  'payment.orderSummary.usage': '使用',
  'payment.shipping.noShipping': '抱歉，暂无可选的运输方式，请联系商家。',
  'payment.checkout.providedByOpay': '由OPay提供支付服务',
  'payment.contactInformation.logout': '退出',
  'payment.shipping.continuePay': '继续去付款',
  'payment.contactInformation.address': '运输地址',
  'payment.orderSummary.title': '订单',
  'payment.orderSummary.calcAtNext': '下一步计算',
  'payment.contactInformation.continueShipping': '继续选择运输方式',
  'payment.contactInformation': '联系信息',
  'payment.shipping.method': '运输方式',
  'payment.navigation.information': '信息',
  'payment.shipping.free': '免费',
  'payment.navigation.shipping': '运输',
  'payment.checkout.method': '方式',
  'payment.backShippingCart': '返回购物车',
  'payment.orderSummary.subTotal': '小计',
  'payment.update': '更改',
  'payment.shipping.logistics': '物流配送',
  'payment.orderSummary.discountCode': '折扣码',
  'payment.orderSummary.discount': '折扣',
  'payment.success.title': '支付成功',
  'payment.contactInformation.formSelectTip': '请从列表中选择一项',
  'payment.checkout.noCheckout': '该店铺未开通支付功能，请联系商家',
  'payment.contactInformation.login': '登录',
  'payment.shipping.deliverTo': '运送至',
  'payment.orderSummary.shippingRate': '运费',
  'payment.success.continueShopping': '继续购物',
  'payment.contactInformation.formInputTip': '请填写该字段',
}
