import { EMAIL_VALIDATE_REG, PASSWORD_VALIDATE_REG } from '@/constants'
/**
 * 表单判断是否为空
 * @param {Any} value 校验的值
 * @example
 * const result = isEmptyValue(null)
 * => true
 * const result = isEmptyValue(0)
 * => false
 */
export const isEmptyValue = value => {
  return [null, undefined, ''].includes(value)
}

/**
 * 表单必填校验规则
 * @param {boolean} required 是否必填
 * @param {String} trigger 触发校验时机
 * rules: {
 *    name: [
 *       validateRequired({trigger:'blur'}),
 *    ],
 * },
 */
export const validateRequired = (trigger = 'change') => {
  return { required: true, message: $t('common.inputRequired'), trigger }
}

/**
 * 表单校验长度规则
 * @param {Number} length 输入的最大长度
 * @param {String} trigger 触发校验时机
 * rules: {
 *     name: [
 *        validateMaxLength(50,'blur'),
 *     ],
 *  },
 */
export const validateMaxLength = (trigger = 'change', length = 50) => {
  return {
    trigger,
    validator: (rule, value, callback) => {
      if (isEmptyValue(value)) {
        callback()
        return
      }
      if (value.length <= length) {
        callback()
      } else {
        callback(
          new Error(
            `${$t('common.formValidate.maxLengthTip')}${length}${$t(
              'common.formValidate.codeText'
            )}`
          )
        )
      }
    },
  }
}

/**
 * 表单必填且校验长度规则
 * @param {Number} length  长度限制
 * @param {String} trigger 触发校验时机
 * rules: {
 *    name: [
 *       validateRequiredAndMaxLength(),
 *    ],
 * },
 */
export const validateRequiredAndMaxLength = (trigger = 'change', length = 50) => {
  return {
    trigger,
    validator: (rule, value, callback) => {
      if (isEmptyValue(value)) {
        callback(new Error($t('common.inputRequired')))
        return
      }
      if (value.length <= length) {
        callback()
      } else {
        callback(
          new Error(
            `${$t('common.formValidate.maxLengthTip')} ${length} ${$t(
              'common.formValidate.codeText'
            )}`
          )
        )
      }
    },
  }
}

/**
 * 表单校验邮件
 * @param {String} trigger 触发校验时机
 * rules: {
 * name: [
 *  validateMail({ trigger = 'blur' }),
 *  ],
 * },
 */
export const validateMail = (trigger = 'change') => {
  return {
    trigger,
    validator: (rule, value, callback) => {
      if (isEmptyValue(value)) {
        callback()
        return
      }
      if (EMAIL_VALIDATE_REG.test(value)) {
        callback()
      } else {
        callback(new Error($t('common.emailFormatTip')))
      }
    },
  }
}

/**
 * 表单校验密码
 * @param {String} trigger 触发校验时机
 * rules: {
 *      password: [
 *        validatorPassword({ trigger = 'blur' }),
 *      ],
 *  },
 */
export const validatePassword = (trigger = 'change') => {
  return {
    trigger,
    validator: (rule, value, callback) => {
      if (isEmptyValue(value)) {
        callback()
        return
      }
      if (PASSWORD_VALIDATE_REG.test(value)) {
        callback()
      } else {
        callback(new Error($t('common.register.passwordFormatTip')))
      }
    },
  }
}
