<template>
  <div class="op-product" @click="goProductDetail">
    <div class="op-product__image-cover" :style="{ borderRadius: imageRatio + 'px' }">
      <img
        v-lazy="productInfo.picUrl || defaultProductImg"
        class="op-product__image-cover__box"
        :style="{ borderRadius: imageRatio + 'px' }"
      />
      <div v-if="productInfo.sellOut === SELL_STATUS.SELL_OUT" class="op-product__image-cover__tag">
        {{ $t('product.list.soldOut') }}
      </div>
    </div>
    <div class="op-product__product-name text-overflow-ellipsis">{{ productInfo.name }}</div>
    <div class="op-product__product-price">
      <span v-if="isShowSalePrice" class="op-product__product-price--sale">
        {{ productInfo.salePrice | formatMoney }}
      </span>
      <span
        v-if="isShowOriginPrice || isThroughOriginPrice"
        :style="{ 'text-decoration': isThroughOriginPrice ? 'line-through' : 'none' }"
        class="op-product__product-price--origin"
      >
        {{ productInfo.originalPrice | formatMoney }}
      </span>
    </div>
    <div v-if="productInfo.commentStatus === 1" class="op-product__product-score">
      <a-rate :default-value="formatStarLevel(productInfo.starLevelAvg)" allow-half disabled />
      <span class="op-product__product-score--summary">({{ productInfo.commentCount || 0 }})</span>
    </div>
  </div>
</template>

<script>
import { isEmptyValue } from '@/utils/validator'
import { SELL_STATUS } from '@/constants'
export default {
  name: 'OpProduct',
  components: {},
  props: {
    productInfo: {
      type: Object,
      default: () => ({}),
    },
    imageRatio: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      SELL_STATUS,
      defaultProductImg:
        'https://saas-fe-prod.pandova.com/static/pandova-mall/images/product/default-product.png',
    }
  },

  computed: {
    isShowSalePrice() {
      return !isEmptyValue(this.productInfo.salePrice)
    },
    isShowOriginPrice() {
      return isEmptyValue(this.productInfo.salePrice)
    },
    isThroughOriginPrice() {
      return (
        !isEmptyValue(this.productInfo.salePrice) &&
        this.productInfo.originalPrice > this.productInfo.salePrice
      )
    },
    isShowSoldOutTag() {
      return (
        !isEmptyValue(this.productInfo.stockCount) &&
        this.productInfo.stockCount === 0 &&
        this.productInfo.id > 0
      )
    },
  },

  methods: {
    formatStarLevel(data) {
      return +data % 1 > 0
        ? +String(data).split('.')[0] + 0.5
        : +data
    },
    goProductDetail() {
      if (!this.productInfo.id || this.productInfo.id < 0) {
        return
      }
      this.$router.push({
        path: `/products/${this.productInfo.id}`,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.op-product {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__image-cover {
    position: relative;
    flex: 1;
    width: 100%;
    cursor: pointer;
    &__box {
      width: 100%;
      height: calc(360px - 20px);
      object-fit: contain;
    }
    &__tag {
      position: absolute;
      bottom: 16px;
      left: 16px;
      padding: 0 12px;
      font-size: 12px;
      line-height: 26px;
      color: var(--color-primary-button-text);
      background: var(--color-primary-button-background);
      border-radius: 30px;
    }
  }
  &__product-name {
    margin: 8px 0;
    cursor: pointer;
  }
  &__product-price {
    display: flex;
    flex-wrap: wrap;
    color: var(--color-product-origin-price);
    &--sale {
      margin-right: 16px;
      color: var(--color-product-sale-price);
    }
  }
  &__product-score {
    .ant-rate {
      margin-right: 16px;
    }
    &--summary {
      font-size: 12px;
      line-height: 26px;
    }
  }
}
@media (max-width: 1440px) {
  .op-product {
    &__image-cover {
      flex: 1;
      width: 100%;
      &__box {
        width: 100%;
        height: calc(25vw - 20px);
        object-fit: contain;
      }
    }
  }
}
@media (max-width: 1200px) {
  .op-product {
    &__image-cover {
      flex: 1;
      width: 100%;
      &__box {
        width: 100%;
        height: calc(33.33vw - 26.6px);
        object-fit: contain;
      }
    }
  }
}
@media (max-width: 768px) {
  .op-product {
    &__image-cover {
      flex: 1;
      width: 100%;
      &__box {
        width: 100%;
        height: calc(50vw - 40px);
        object-fit: contain;
      }
    }
  }
}
@media (max-width: 576px) {
  .op-product {
    &__image-cover {
      flex: 1;
      width: 100%;
      &__box {
        width: 100%;
        height: calc(50vw - 16px);
        object-fit: contain;
      }
    }
  }
}
</style>
