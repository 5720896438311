<template>
  <div class="custom-upload-container">
    <a-upload
      list-type="picture-card"
      :file-list="pictureList"
      :show-upload-list="{ showRemoveIcon: true, showPreviewIcon: false }"
      :custom-request="handleFileUpload"
      @change="handleUploadPictureChange"
    >
      <div v-if="pictureList.length < imgMax">
        <img class="icon-plus" :src="IconPlusImg" />
      </div>
    </a-upload>
  </div>
</template>
<script>
import IconPlusImg from '@/assets/icons/icon-plus-img.png'
import { uploadSingleImage } from '@/api/index'

export default {
  name: 'OpUpload',
  props: {
    imgMax: {
      type: [Number, String],
      default: 9
    }
  },
  data() {
    return {
      IconPlusImg,
      pictureCount: 1,
      pictureList: []
    }
  },
  methods: {
    async requestUploadImage({ index, params }) {
      const response = await uploadSingleImage(params)
      // const response = {
      //   code: 200,
      //   data: 'https://opay-saas.oss-cn-hangzhou.aliyuncs.com/comment/40000522/587caa2a-4ec1-4a5d-b03d-95d340008522.png'
      // }
      const { code, data } = response
      const pictureList = JSON.parse(JSON.stringify(this.pictureList))
      if (code === 200) {
        const picture = pictureList[index]
        picture.status = 'done'
        picture.url = data
        pictureList.splice(index, 1, picture)
      } else {
        pictureList.splice(index, 1)
      }
      this.pictureList = pictureList
      this.$emit('change', this.pictureList)
    },
    handleFileUpload(item) {
      const { file } = item
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        // TODO
        this.$message.error('You can only upload JPG file!')
      }
      const isLt2M = true
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!')
      // }
      if (isJpgOrPng && isLt2M) {
        const length = this.pictureList.length
        this.pictureList = this.pictureList.concat({
          uid: `-${++this.pictureCount}`,
          name: file.name,
          status: 'uploading',
          url: '',
        })
        const formData = new FormData()
        formData.append('file', file)
        formData.append('moduleName', 'comment')
        this.requestUploadImage({
          index: length,
          params: formData
        })
      }
    },
    handleUploadPictureChange({ file }) {
      const { status, uid } = file
      if (status === 'removed') {
        const index = this.pictureList.findIndex(item => item.uid === uid)
        if (index > -1) {
          this.pictureList.splice(index, 1)
          this.$emit('change', this.pictureList)
        }
      }
    },
  }
}
</script>
<style lang="less" scoped>
.custom-upload-container {
  .icon-plus {
    width: 104px;
    height: 104px;
    cursor: pointer;
  }
  /deep/.ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }
  /deep/.ant-upload.ant-upload-select-picture-card {
    border-radius: 0;
    border: none;
    background-color: transparent;
  }
}
</style>
