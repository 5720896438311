<template>
  <div
    class="layout-footer"
    :style="buildStyle(sectionJson.settings)"
  >
    <div
      class="layout-footer__container"
      :class="{ 'jc-fs': quickLinks.length }"
    >
      <div
        v-for="(item, index) in quickLinks"
        :key="index"
        class="layout-footer__container__quick-links"
      >
        <div class="quick-links-title">{{ item.title }}</div>
        <div class="quick-links-content">
          <div
            v-for="(childItem, childIndex) in item.items || []"
            :key="index + '_' + childIndex"
            class="quick-links-item"
          >
            <span @click="handleQuickLinkClick(childItem)">{{ childItem.title }}</span>
          </div>
        </div>
      </div>
      <div class="layout-footer__container__left-info">
        <div class="layout-footer__container__left-info__social-app">
          <template v-for="item in socialAppKey">
            <a-icon
              v-if="socialAppSettings[item.key]"
              :key="item.key"
              class="layout-footer__container__left-info__social-app__item"
              :component="item.icon"
              @click="jumpSocialAPP(socialAppSettings[item.key])"
            />
          </template>
        </div>
      </div>
      <div class="layout-footer__container__email-subscription">
        <div class="layout-footer__container__email-subscription__content">
          <div
            class="layout-footer__container__email-subscription__content__heading"
          >{{ sectionJson.settings && sectionJson.settings.emailHeading }}</div>
          <div
            class="layout-footer__container__email-subscription__content__subheading"
          >{{ sectionJson.settings && sectionJson.settings.emailSunHeading }}</div>
        </div>
        <div class="layout-footer__container__email-subscription__email">
          <a-input
            v-model.trim="userInfo.mail"
            size="large"
            disabled
            :placeholder="$t('common.footer.subscribeTip')"
            class="email-input"
          />
          <op-button
            type="primary"
            @click="subscribe"
          >{{ $t('common.footer.subscribe') }}</op-button>
        </div>
      </div>
    </div>

    <div class="layout-footer__info">
      <div class="layout-footer__info__copyright">{{ $t('common.footer.copyright') }}</div>
      <img
        class="layout-footer__info__payment-methods"
        :src="paymentMethodsImageUrl"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { subscribe } from '@/api'
import IconFacebook from '@/assets/icons/icon-facebook.svg'
import IconInstagram from '@/assets/icons/icon-instagram.svg'
import IconTwitter from '@/assets/icons/icon-twitter.svg'
import IconPinterest from '@/assets/icons/icon-pinterest.svg'
import IconYouTube from '@/assets/icons/icon-youtube.svg'
export default {
  name: 'Footer',
  components: {},
  props: {
    sectionJson: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      unLoginSubscribe: false,
      socialAppKey: [
        { key: 'facebook', icon: IconFacebook },
        { key: 'instagram', icon: IconInstagram },
        { key: 'twitter', icon: IconTwitter },
        { key: 'pinterest', icon: IconPinterest },
        { key: 'youTube', icon: IconYouTube },
      ],
      quickLinks: [],
    }
  },

  computed: {
    inputDisabled() {
      return !!(this.userInfo && this.userInfo.mail)
    },
    ...mapState(['isAlreadyLogin', 'shopInfo', 'userInfo']),
    socialAppSettings() {
      return this.$store.state.globalSettings.socialApp || {}
    },
    paymentMethodsImageUrl() {
      return this.shopInfo.country === 'EG'
        ? 'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/common/payment-method-three.png'
        : 'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/common/payment-method-two.png'
    },
  },
  watch: {
    isAlreadyLogin: {
      handler() {
        this.unLoginSubscribe && this.subscribe()
      },
    },
    sectionJson: {
      handler() {
        this.initQuickLinks()
      },
      deep: true,
    },
  },
  methods: {
    initQuickLinks() {
      const quickLinks = []
      const { blockOrder, blocks } = this.sectionJson
      if (Array.isArray(blockOrder) && blockOrder.length) {
        blockOrder.forEach(item => {
          const { settings } = blocks[item] || {}
          const { display, footerItems } = settings
          // 如果展示QuickLinks则执行渲染
          if (display) {
            quickLinks.push(footerItems || {})
          }
        })
      }
      this.$set(this, 'quickLinks', quickLinks)
    },
    buildStyle(styleJson) {
      return {
        marginTop: styleJson && styleJson.marginTop,
        backgroundColor: styleJson && styleJson.colorBg,
        paddingTop: styleJson && styleJson.paddingTop + 'px',
        paddingBottom: styleJson && styleJson.paddingBottom + 'px',
        color: styleJson && styleJson.colorText,
      }
    },
    async subscribe() {
      if (!this.isAlreadyLogin) {
        this.$message.warning(this.$t('common.footer.subscribeTip'))
        this.unLoginSubscribe = true
        this.$store.commit('openLoginDialog', {
          currentForm: 'loginForm',
        })
        return
      }
      const { message } = await subscribe({ mail: this.userInfo.mail })
      this.unLoginSubscribe = false
      this.$message.success(message)
    },
    jumpSocialAPP(url) {
      window.open(url, '_blank')
    },
    handleQuickLinkClick(item) {
      const { path } = item
      let url = path
      if (!/^http(s)?/.test(url)) {
        url = window.location.origin + url
      }
      window.open(url)
    },
  },
}
</script>
<style lang="less" scoped>
.layout-footer {
  width: 100%;
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 0 32px 32px;
    border-bottom: 1px solid #d3d8dd;
    &.jc-fs {
      justify-content: flex-start;
    }
    .layout-footer__container__quick-links {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      width: 25%;
      margin: 0 0 24px 0;
      .quick-links-title {
        margin-bottom: 16px;
        font-size: 22px;
        font-weight: 500;
      }
      .quick-links-content {
        .quick-links-item {
          line-height: 30px;
          white-space: break-spaces;
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
    &__left-info {
      &__social-app {
        display: flex;
        &__item {
          margin-right: 8px;
          font-size: 22px;
          cursor: pointer;
        }
      }
    }
    &__email-subscription {
      width: 400px;
      text-align: left;

      &__content {
        &__heading {
          font-size: 22px;
          font-weight: 500;
        }
        &__subheading {
          margin: 16px 0;
          line-height: 30px;
          white-space: break-spaces;
        }
      }

      &__email {
        display: flex;
        justify-content: flex-start;

        /deep/ .op-button {
          min-width: 100px;
          border-radius: 0;
        }
        .email-input {
          width: 300px;
          border-radius: 0;
        }
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    &__copyright {
      margin-right: 32px;
      text-align: center;
    }
    &__payment-methods {
      display: block;
      height: 34px;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .layout-footer {
    &__container {
      display: block;
    }
  }
}

@media (max-width: 576px) {
  .layout-footer {
    &__container {
      display: block;
      &__email-subscription {
        width: 310px;
        margin-top: 24px;
        /deep/ .op-button {
          min-width: 70px;
          border-radius: 0;
        }
        &__email {
          .email-input {
            width: 240px;
          }
        }
      }
    }
    &__info {
      display: block;
      &__copyright {
        margin-right: 0;
        text-align: center;
      }
      &__payment-methods {
        margin: 16px auto 0;
      }
    }
  }
}
</style>
