<template>
  <div
    v-show="shopInfo && shopInfo.socialInfo && shopInfo.socialInfo.whatsApp"
    class="whats-app"
    @click="goHelpCenter"
  >
    <img
      src="https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/components/whats-app.png"
    />
  </div>
</template>

<script>
export default {
  name: 'WhatsApp',
  props: {},
  data() {
    return {}
  },

  computed: {
    shopInfo() {
      return this.$store.state.shopInfo
    },
  },

  methods: {
    goHelpCenter() {
      const { internationalCode, whatsApp } = this.shopInfo?.socialInfo || {}
      const phone = `${internationalCode || ''}${whatsApp || ''}`.replace('+', '')
      window.open(`https://api.whatsapp.com/send?phone=${phone}`, '_blank')
    },
  },
}
</script>
<style lang="less" scoped>
.whats-app {
  position: fixed;
  right: 24px;
  bottom: 20px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  cursor: pointer;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
