<template>
  <div class="comment-write-container">
    <div class="write-form-moddule">
      <a-form-model ref="commentForm" :model="commentDetails" :rules="commentRules">
        <div v-if="!Object.keys(userInfo).length" class="form-item flex-half">
          <div class="inner-item">
            <a-form-model-item prop="firstName">
              <div class="inner-label">
                {{ $t('product.comment.writeCommentFirstName') }}
              </div>
              <div class="inner-input">
                <a-input
                  v-model="commentDetails.firstName"
                  :placeholder="$t('product.comment.writeCommentFirstNamePlaceholder')"
                  allow-clear
                />
              </div>
            </a-form-model-item>
          </div>
          <div class="inner-item">
            <a-form-model-item prop="lastName">
              <div class="inner-label">
                {{ $t('product.comment.writeCommentLastName') }}
              </div>
              <div class="inner-input">
                <a-input
                  v-model="commentDetails.lastName"
                  :placeholder="$t('product.comment.writeCommentLastNamePlaceholder')"
                  allow-clear
                />
              </div>
            </a-form-model-item>
          </div>
          <div class="inner-item">
            <a-form-model-item prop="mail">
              <div class="inner-label">
                {{ $t('product.comment.writeCommentEmail') }}
              </div>
              <div class="inner-input">
                <a-input
                  v-model="commentDetails.mail"
                  :placeholder="$t('product.comment.writeCommentEmailPlaceholder')"
                  allow-clear
                />
              </div>
            </a-form-model-item>
          </div>
        </div>
        <div class="form-item">
          <div class="inner-item">
            <div class="inner-label">
              <div>{{ $t('product.comment.writeCommentRating') }}</div>
              <div class="label-mark">
                <a-rate v-model="commentDetails.starLevel" :allow-clear="false" />
              </div>
            </div>
            <div class="inner-input">
              <a-textarea
                v-model="commentDetails.commentContent"
                :placeholder="$t('product.comment.writeCommentPlaceholder')"
                allow-clear
                :rows="6"
                :max-length="1000"
              />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="inner-item">
            <div class="inner-label flex-column">
              <div>{{ $t('product.comment.writeCommentAddImage') }}</div>
              <div class="label-description">
                {{ formatAddImagePlaceholder() }}
              </div>
            </div>
            <div class="inner-input">
              <op-upload @change="handleUploadComomentImageChange" />
            </div>
          </div>
        </div>
      </a-form-model>
      <div class="form-item">
        <div class="operation-module">
          <div class="operation-item selected" @click="handleSaveClick">
            {{ $t('product.comment.writeCommentSubmit') }}
          </div>
          <div class="operation-item" @click="$emit('update:visible', false)">
            {{ $t('product.comment.writeCommentCancel') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlusImg from '@/assets/icons/icon-plus-img.png'
import { addProductCommentByVisitor, addProductCommentByLogin } from '@/api/index'
import {
  isEmptyValue,
} from '@/utils/validator'
import { EMAIL_VALIDATE_REG } from '@/constants'

export default {
  name: 'CommentWrite',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    goodId: {
      type: [String, Number],
      default: -1,
    },
  },
  data() {
    const validatorFirstName = (rule, value, callback) => {
      if (Object.keys(this.userInfo).length) {
        return callback()
      }
      if (isEmptyValue(value)) {
        callback(new Error($t('product.comment.writeCommentFirstNamePlaceholder')))
      } else {
        callback()
      }
    }
    const validatorLastName = (rule, value, callback) => {
      if (Object.keys(this.userInfo).length) {
        return callback()
      }
      if (isEmptyValue(value)) {
        callback(new Error($t('product.comment.writeCommentLastNamePlaceholder')))
      } else {
        callback()
      }
    }
    const validatorMail = (rule, value, callback) => {
      if (Object.keys(this.userInfo).length) {
        return callback()
      }
      if (isEmptyValue(value)) {
        callback(new Error($t('product.comment.writeCommentEmailPlaceholder')))
        return
      }
      if (EMAIL_VALIDATE_REG.test(value)) {
        callback()
      } else {
        callback(new Error($t('common.emailFormatTip')))
      }
    }
    return {
      IconPlusImg,
      pictureCount: 1,
      commentDetails: {
        goodsId: 0,
        firstName: '',
        lastName: '',
        mail: '',
        starLevel: 5,
        commentContent: '',
        commentImg: []
      },
      commentRules: {
        firstName: [
          { required: true, validator: validatorFirstName, trigger: 'blur' }
        ],
        lastName: [
          { required: true, validator: validatorLastName, trigger: 'blur' }
        ],
        mail: [
          { required: true, validator: validatorMail, trigger: 'blur' }
        ]
      },
      pictureList: [
        // {
        //   uid: '-1',
        //   name: '333',
        //   status: 'done',
        //   url: 'https://www.surely.cool/surely-vue-logo.png'
        // },
        // {
        //   uid: '-4',
        //   name: 'image.png',
        //   status: 'error',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //   uid: '-7',
        //   name: '',
        //   status: 'uploading',
        //   url: '',
        // },
        // {
        //   uid: '-2',
        //   name: 'image.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
      ]
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  mounted() {
  },
  methods: {
    formatAddImagePlaceholder() {
      return this.$t('product.comment.writeCommentAddImagePlaceholder').replace('$[X]', 9)
    },
    async requestAddProductCommentByVisitor(params) {
      const { code, message } = await addProductCommentByVisitor(params)
      this.$message.success(message)
      if (code === 200) {
        this.$emit('confirm')
        this.$emit('update:visible', false)
      }
    },
    async requestAddProductCommentByLogin(params) {
      delete params.firstName
      delete params.lastName
      delete params.mail
      const { code, message } = await addProductCommentByLogin(params)
      this.$message.success(message)
      if (code === 200) {
        this.$emit('confirm')
        this.$emit('update:visible', false)
      }
    },
    handleUploadComomentImageChange(data) {
      this.pictureList = data.map(item => item.url)
    },
    async handleSaveClick() {
      await this.$refs.commentForm.validate()
      const params = { ...this.commentDetails }
      params.goodsId = Number(this.goodId)
      params.commentImg = this.pictureList
      if (Object.keys(this.userInfo).length) {
        this.requestAddProductCommentByLogin(params)
      } else {
        this.requestAddProductCommentByVisitor(params)
      }
    }
  },
}
</script>

<style lang="less" scoped>
.comment-write-container {
  padding: 16px 0;
  .write-form-moddule {
    .form-item {
      margin-bottom: 24px;
      &.flex-half {
        display: flex;
        .inner-item {
          width: calc(33% - 12px);
          margin-right: 24px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .inner-item {
        /deep/ input {
          height: 48px;
        }
        .inner-label {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          color: #47516a;
          font-size: 14px;
          font-weight: 500;
          font-family: PingFang SC;
          line-height: 20px;
          .label-mark {
            margin-left: 12px;
            /deep/.anticon {
              font-size: 16px;
            }
          }
          .label-description {
            margin-left: 12px;
            color: #959ead;
            font-size: 14px;
            font-weight: 500;
            font-family: PingFang SC;
            line-height: 20px;
          }
        }
        .inner-input {
          color: #47516a;
          font-size: 14px;
          font-weight: 400;
          font-family: PingFang SC;
          line-height: 20px;
          .icon-plus {
            width: 90px;
            height: 90px;
            cursor: pointer;
          }
          /deep/.ant-upload.ant-upload-select-picture-card {
            border-radius: 0;
            border: none;
            background-color: transparent;
          }
          /deep/.ant-upload-list-picture-card-container {
            // width: 56px;
            // height: 56px;
          }
          /deep/.ant-upload-list-picture-card .ant-upload-list-item {
            // width: 56px;
            // height: 56px;
          }
        }
      }
      .operation-module {
        display: flex;
        .operation-item {
          padding: 0 10px;
          margin-right: 8px;
          line-height: 48px;
          font-size: 14px;
          font-weight: 700;
          font-family: PingFang SC;
          color: #8991a4;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          &.selected {
            color: #ffffff;
            background-color: #2a334a;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .write-form-moddule {
    .ant-form {
      .form-item {
        &.flex-half {
          flex-direction: column;
          .inner-item {
            width: 100%;
          }
        }
        .inner-label {
          &.flex-column {
            flex-direction: column;
            align-items: flex-start;
            .label-description {
              margin-left: 0;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
