<template>
  <div
    class="op-link"
    @click="handleClick"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'OpLink',
  methods: {
    handleClick(event) {
      this.$emit('click', event)
    },
  },
}
</script>
<style lang="less" scoped>
.op-link {
  display: inline-flex;
  padding: 0 4px;
  color: var(--color-primary-button-background);
  cursor: pointer;
}
</style>
