<template>
  <div
    class="layout-header"
    :style="layoutHeaderStyle(sectionJson.settings)"
  >
    <div
      v-if="isShowPcHeader"
      class="layout-header__pc"
    >
      <div
        class="layout-header__pc__logo"
        @click="jumpLink('/')"
      >
        <img
          v-if="sectionJson.settings && sectionJson.settings.logoUrl"
          v-compress="{ src: sectionJson.settings.logoUrl }"
          class="layout-header__pc__logo-image"
        />
        <span v-else>{{ shopInfo.shopName }}</span>
      </div>
      <!-- <header-pc-menu :current-menu="currentMenu" :menu-list="menuList"></header-pc-menu> -->
      <header-pc-menu
        v-if="navigations.length"
        :current-menu="currentMenu"
        :menu-list="navigations"
      ></header-pc-menu>
      <header-pc-operation @goMyAccount="goMyAccount"></header-pc-operation>
    </div>
    <div
      v-else
      class="layout-header__mobile"
    >
      <a-icon
        class="layout-header__mobile__open-menu-icon"
        type="menu"
        :style="{ fontSize: '24px', cursor: 'pointer' }"
        @click="openMobileMenu"
      />
      <div
        class="layout-header__mobile__logo"
        @click="jumpLink('/')"
      >
        <img
          v-if="sectionJson.settings && sectionJson.settings.logoUrl"
          v-compress="{ src: sectionJson.settings.logoUrl }"
          class="layout-header__mobile__logo-image"
        />
        <span v-else>{{ shopInfo.shopName }}</span>
      </div>
      <header-mobile-operation @goMyAccount="goMyAccount"></header-mobile-operation>
      <!-- <header-mobile-menu
        :menu-list="menuList"
        :mobile-menu-is-show="mobileMenuIsShow"
        @closeMobileMenu="closeMobileMenu"
      ></header-mobile-menu>-->
      <header-mobile-menu
        v-if="navigations.length"
        :menu-list="navigations"
        :mobile-menu-is-show="mobileMenuIsShow"
        @closeMobileMenu="closeMobileMenu"
      ></header-mobile-menu>
    </div>
  </div>
</template>

<script>
import { setCssVars } from '@/utils/theme'
import { jumpLink } from '@/utils/index'
import { getCollectionList } from '@/api'
import { mapState } from 'vuex'
import HeaderPcMenu from './header-menu/header-pc-menu.vue'
import HeaderMobileMenu from './header-menu/header-mobile-menu.vue'
import HeaderPcOperation from './header-operation/header-pc-operation.vue'
import HeaderMobileOperation from './header-operation/header-mobile-operation.vue'
export default {
  name: 'Header',
  components: {
    HeaderPcMenu,
    HeaderMobileMenu,
    HeaderPcOperation,
    HeaderMobileOperation,
  },
  props: {
    sectionJson: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowPcHeader: true,
      mobileMenuIsShow: false,
      subMenuList: [],
      navigations: [],
    }
  },

  computed: {
    menuList() {
      return [
        { key: 'home', path: '/', title: this.$t('common.header.homePage'), children: null },
        {
          key: 'allProducts',
          path: '/products/all',
          title: this.$t('common.header.allProducts'),
          children: null,
        },
        {
          key: 'collections',
          path: '/collections',
          title: this.$t('common.header.collection'),
          children: this.subMenuList,
        },
      ]
    },

    ...mapState(['isAlreadyLogin', 'shopInfo']),
    currentMenu() {
      return [this.$route.path]
    },
  },
  watch: {
    sectionJson: {
      deep: true,
      handler(val) {
        this.initNavigation()
        this.setAntCss()
      },
    },
  },
  created() {
    this.setAntCss()
    // this.getCollectionList()
  },
  mounted() {
    const width = document.documentElement.clientWidth
    this.setShowType(width)
    window.addEventListener('resize', this.resizeScreen)
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeScreen)
  },

  methods: {
    jumpLink,
    resizeScreen(e) {
      const width = e.target.innerWidth
      this.setShowType(width)
    },
    setShowType(width) {
      if (width > 768) {
        this.isShowPcHeader = true
      } else {
        this.isShowPcHeader = false
      }
    },
    layoutHeaderStyle(styleJson) {
      return {
        marginBottom: styleJson && styleJson.marginBottom + 'px',
        backgroundColor: styleJson && styleJson.colorBg,
        color: styleJson && styleJson.colorText,
        borderBottom: styleJson && styleJson.showLineSeparator ? '1px solid #dde0e3' : 'none',
      }
    },
    async getCollectionList() {
      const { data } = await getCollectionList({})
      const collections = data || []
      this.subMenuList = collections.map(item => {
        return {
          key: 'collections' + item.id,
          path: `/collections/${item.id}`,
          title: item.name,
          children: null,
        }
      })
    },
    setAntCss() {
      const {
        colorText,
        colorBg,
        subMenuColorText,
        subMenuColorBg,
        mobileMenuColorBg,
        mobileMenuColorText,
      } = this.sectionJson.settings || {}
      setCssVars({
        'ant-color-text': colorText,
        'ant-color-bg': colorBg,
        'ant-sub-menu-color-text': subMenuColorText,
        'ant-sub-menu-color-bg': subMenuColorBg,
        'mobile-menu-color-bg': mobileMenuColorBg,
        'mobile-menu-color-text': mobileMenuColorText,
        'layout-header-color-bg': colorBg,
        'layout-header-color-text': colorText,
      })
    },

    openMobileMenu() {
      this.mobileMenuIsShow = true
    },
    closeMobileMenu() {
      this.mobileMenuIsShow = false
    },
    goMyAccount() {
      if (!this.isAlreadyLogin) {
        this.$store.commit('openLoginDialog', {
          currentForm: 'loginForm',
          returnTo: '/account',
        })
        return
      }
      this.$router.push({
        path: '/account',
      })
    },
    async initNavigation() {
      // 菜单项类型（必传)，可选项:[home,allProduct,productDetail,productGroup,allProductGroup,customPage,customLink,search]
      // 如果是 allProductGroup 系列则需要查询下系列子菜单将导航条绘制下拉框的展示方式
      const { headerItems } = this.sectionJson?.settings || {}
      const { items } = headerItems || {}
      // items.push({
      //   path: '/collections/all',
      //   title: 'test',
      //   value: 'allProduct',
      // })
      const navigations = []
      if (Array.isArray(items)) {
        //  { key: 'home', path: '/', title: this.$t('common.header.homePage'), children: null },
        //   {
        //     key: 'allProducts',
        //     path: '/products/all',
        //     title: this.$t('common.header.allProducts'),
        //     children: null,
        //   },
        //   {
        //     key: 'collections',
        //     path: '/collections',
        //     title: this.$t('common.header.collection'),
        //     children: this.subMenuList,
        //   },
        for (let i = 0; i < items.length; i++) {
          const { title, value, path } = items[i]
          navigations.push({
            key: value,
            path: path,
            title: title,
            children: null,
          })
          if (value === 'allProductGroup') {
            ;(async index => {
              await this.getCollectionList()
              const navigations = JSON.parse(JSON.stringify(this.navigations))
              const navigation = this.navigations[index]
              navigation.children = this.subMenuList
              navigations.splice(index, 1, navigation)
              this.$set(this, 'navigations', [])
              this.$nextTick(() => {
                setTimeout(() => {
                  this.navigations = navigations
                })
              })
            })(i)
          }
        }
      }
      this.navigations = navigations
    },
  },
}
</script>
<style lang="less" scoped>
.layout-header {
  width: 100%;

  &__pc {
    display: flex;
    align-items: center;
    padding: 8px 32px;
    &__logo {
      font-weight: 500;
      color: var(--layout-header-color-text);
      cursor: pointer;
      &-image {
        display: block;
        width: 120px;
        max-height: 40px;
        object-fit: contain;
      }
    }
  }
  &__mobile {
    display: flex;
    align-items: center;
    padding: 8px;
    &__open-menu-icon {
      margin-right: 16px;
    }
    &__logo {
      color: var(--layout-header-color-text);
      cursor: pointer;
      &-image {
        display: block;
        width: 90px;
        max-height: 30px;
        object-fit: contain;
      }
    }
  }
  @media (min-width: 768px) {
    .layout-header {
      &__mobile {
        display: none;
      }
    }
  }
  @media (max-width: 768px) {
    .layout-header {
      &__pc {
        display: none;
      }
      &__mobile {
        padding: 8px 24px;
      }
    }
  }
  @media (max-width: 576px) {
    .layout-header {
      &__mobile {
        padding: 8px 16px;
      }
    }
  }
}
</style>
