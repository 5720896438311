<template>
  <div class="address-form">
    <a-form-model
      ref="addressForm"
      layout="vertical"
      :model="formData"
      :rules="rules"
    >
      <div class="person-name">
        <div class="first-name">
          <a-form-model-item
            :label="$t('common.addressForm.firstName')"
            prop="firstName"
          >
            <a-input
              v-model="formData.firstName"
              size="large"
            />
          </a-form-model-item>
        </div>
        <div class="last-name">
          <a-form-model-item
            prop="lastName"
            :label="$t('common.addressForm.lastName')"
          >
            <a-input
              v-model="formData.lastName"
              size="large"
            />
          </a-form-model-item>
        </div>
      </div>
      <div class="region">
        <div class="region-item">
          <a-form-model-item prop="stateCode">
            <a-select
              v-model="formData.stateCode"
              :placeholder="$t('common.addressForm.province')"
              size="large"
            >
              <a-select-option
                v-for="item in areaList"
                :key="item.id"
                :value="item.code"
                @click="selectState(item)"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <div class="region-item">
          <a-form-model-item prop="cityCode">
            <a-select
              v-model="formData.cityCode"
              :placeholder="$t('common.addressForm.city')"
              size="large"
            >
              <a-select-option
                v-for="item in cityList"
                :key="item.id"
                :value="item.code"
                @click="selectCity(item)"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <div class="region-item">
          <a-form-model-item prop="zipCode">
            <a-input
              v-model.trim="formData.zipCode"
              size="large"
              :placeholder="$t('common.addressForm.zipCode')"
            />
          </a-form-model-item>
        </div>
      </div>
      <a-form-model-item prop="address">
        <a-input
          v-model="formData.address"
          size="large"
          :placeholder="$t('common.addressForm.detailAddress')"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-input
          v-model="formData.apartment"
          :placeholder="$t('common.addressForm.apartment')"
          size="large"
        />
      </a-form-model-item>
      <div class="ant-col ant-form-item-label">{{ $t('common.addressForm.phone') }}</div>
      <a-row
        type="flex"
        :gutter="[8, 8]"
      >
        <a-col
          :xs="countryImage ? 24 : 14"
          :md="5"
          :lg="5"
        >
          <a-dropdown
            size="large"
            :trigger="['click']"
            class="address-form__dropdown"
          >
            <a-row
              type="flex"
              justify="space-between"
              align="middle"
              class="address-form__dropdown-row"
            >
              <div v-if="countryImage">
                <img :src="countryImage" />
                <span class="address-form__dropdown-row__text">{{ formData.internationalCode }}</span>
              </div>
              <div v-else>{{ $t('common.addressForm.othersText') }}</div>
              <a-icon type="down" />
            </a-row>
            <a-menu slot="overlay">
              <a-menu-item
                v-for="(item, index) in countryList"
                :key="index"
                class="address-form__dropdown__item"
                @click="changeCountry(item)"
              >
                <img
                  class="op-language-dropdown__language-menu__item__flag"
                  :src="LANGUAGE_COUNTRY_FLAG[item.internationalDialing]"
                />
                <span class="address-form__dropdown-row__text">{{ item.label }}</span>
                <span class="address-form__dropdown-row__text">{{ item.internationalDialing }}</span>
              </a-menu-item>
              <a-menu-item
                class="address-form__dropdown__item"
                @click="setOtherCode"
              >{{ $t('common.addressForm.othersText') }}</a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-col>
        <a-col
          v-show="!countryImage"
          :xs="10"
          :md="3"
          :lg="3"
        >
          <a-form-model-item prop="internationalCode">
            <a-input
              v-model.trim="formData.internationalCode"
              size="large"
            />
          </a-form-model-item>
        </a-col>
        <a-col
          :xs="24"
          :md="countryImage ? 19 : 16"
          :lg="countryImage ? 19 : 16"
        >
          <a-form-model-item prop="phone">
            <a-input
              v-model.trim="formData.phone"
              size="large"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { getAreaList, getCountryList } from '@/api'
import { LANGUAGE_COUNTRY_FLAG } from '@/constants'
import {
  validateRequired,
  validateRequiredAndMaxLength,
  validateMaxLength,
  isEmptyValue,
} from '@/utils/validator'
import { mapState } from 'vuex'
export default {
  name: 'AddressForm',
  components: {},
  props: {
    addressDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {},
      countryList: [],
      areaList: [],
      LANGUAGE_COUNTRY_FLAG,
      defaultCountryLabel: '',
      activeCountryInfo: {},
      isOtherCode: false,
    }
  },
  computed: {
    ...mapState(['shopInfo']),
    countryImage() {
      return LANGUAGE_COUNTRY_FLAG[this.formData.internationalCode] || ''
    },
    rules() {
      return {
        firstName: [validateRequiredAndMaxLength()],
        lastName: [validateRequiredAndMaxLength()],
        countryCode: [validateRequired()],
        stateCode: [validateRequired()],
        zipCode: [validateRequiredAndMaxLength()],
        cityCode: [validateRequiredAndMaxLength()],
        address: [validateRequiredAndMaxLength('change', 200)],
        internationalCode: [
          validateRequired(),
          {
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (value === '+' || isEmptyValue(value)) {
                callback(new Error($t('common.inputRequired')))
              } else {
                callback()
              }
            },
          },
        ],
        phone: [
          validateMaxLength(),
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9]*$/
              if (value === '') {
                callback(new Error(this.$t('common.inputRequired')))
              } else if (!reg.test(value)) {
                callback(new Error(this.$t('common.phoneFormatTip')))
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
      }
    },
    cityList() {
      return this.areaList?.find(item => item.code === this.formData.stateCode)?.cities || []
    },
  },

  watch: {
    addressDetail: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.formData = val
          this.setDefaultCountryInfo()
        }
      },
      deep: true,
    },
  },

  created() {
    this.getCountryList()
    this.getAreaList()
  },

  methods: {
    getCountryInfo() {
      return (this.countryList || []).find(item => item.value === this.shopInfo.country) || {}
    },
    selectState(option) {
      this.formData.stateValue = option.name
      this.formData.city = option.cities[0]?.name
      this.$set(this.formData, 'cityCode', option.cities[0]?.code)
    },
    selectCity(option) {
      this.formData.city = option.name
    },
    changeCountry(item) {
      this.$set(this.formData, 'internationalCode', item.internationalDialing)
      this.isOtherCode = false
    },
    setOtherCode() {
      this.isOtherCode = true
      this.$set(this.formData, 'internationalCode', '+')
    },
    setDefaultCountryInfo() {
      const countryInfo = this.getCountryInfo()
      !this.formData.internationalCode &&
        !this.isOtherCode &&
        this.$set(this.formData, 'internationalCode', countryInfo?.internationalDialing)
      this.formData.countryCode = countryInfo.value
      this.formData.countryValue = countryInfo.label
    },
    async getCountryList() {
      const { data } = await getCountryList()
      this.countryList = data || []
      this.setDefaultCountryInfo()
    },
    async getAreaList() {
      const { data } = await getAreaList({ countryId: this.shopInfo.country })
      this.areaList = data || []
    },

    async submit() {
      try {
        await this.$refs.addressForm.validate()
        return { valid: true, address: this.formData }
      } catch (err) {
        return { valid: false, address: this.formData }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.address-form {
  .person-name {
    display: flex;
    .first-name {
      flex: 1;
      margin-right: 16px;
    }
    .last-name {
      flex: 1;
    }
  }
  .region {
    display: flex;
    .region-item {
      flex: 1;
    }
    .region-item--padding {
      padding-top: 29px;
    }
    .region-item:not(:last-child) {
      margin-right: 16px;
    }
  }
  .form-phone {
    display: flex;
    &-dialing {
      width: 260px;
    }
    &-number {
      flex: 1;
      margin-top: 29px;
      margin-left: 16px;
    }
  }
  &__dropdown {
    &__item:hover {
      background-color: rgba(244, 245, 246, 1);
    }
  }
  &__dropdown-row {
    width: 100%;
    height: 40px;
    padding: 6px 11px;
    margin-bottom: 16px;
    font-size: 16px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #c9d2e1;
    border-radius: 4px;
    &__text {
      margin-left: 12px;
    }
  }
}
@media (max-width: 768px) {
  .address-form {
    .person-name {
      display: block;
      .first-name {
        width: 100%;
        margin-right: 0;
      }
      .last-name {
        width: 100%;
      }
    }
    .region {
      display: block;
      .region-item {
        width: 100%;
      }
      .region-item--padding {
        padding-top: 0;
      }
      .region-item:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
</style>
