<template>
  <div
    class="site-layout-container"
    :style="{ background: pageBgWhite ? '#ffffff' : '#f9fafb' }"
  >
    <announcement-bar
      v-if="isShowAnnouncementBar"
      :id="getSectionEleId(announcementBarSectionKey)"
      :class="sectionIsActive(announcementBarSectionKey)"
      :section-json="sectionJson(announcementBarSectionKey)"
      class="site-layout-container__announcements"
    ></announcement-bar>
    <layout-header
      :id="getSectionEleId(layoutHeaderSectionKey)"
      :class="sectionIsActive(layoutHeaderSectionKey)"
      :section-json="sectionJson(layoutHeaderSectionKey)"
      class="site-layout-container__header"
    ></layout-header>
    <router-view class="site-layout-container__content" />
    <layout-footer
      :id="getSectionEleId(layoutFooterSectionKey)"
      :class="sectionIsActive(layoutFooterSectionKey)"
      :section-json="sectionJson(layoutFooterSectionKey)"
      class="site-layout-container__footer"
    ></layout-footer>
  </div>
</template>

<script>
import LayoutFooter from './components/layout-footer.vue'
import LayoutHeader from './components/layout-header.vue'
import AnnouncementBar from './components/announcement-bar'
import { getSectionEleId } from '@/utils/index'
export default {
  name: 'SiteLayout',
  components: {
    LayoutHeader,
    LayoutFooter,
    AnnouncementBar,
  },
  data() {
    return {
      pagePathList: ['/', '/product'],
    }
  },
  computed: {
    pageBgWhite() {
      return (
        this.pagePathList.includes(this.$route.matched[0].path) ||
        this.pagePathList.includes(this.$route.matched[0].redirect)
      )
    },
    layoutSectionOrder() {
      return (
        (this.$store.state.layoutTemplate && this.$store.state.layoutTemplate.layoutSectionOrder) ||
        []
      )
    },
    layoutSections() {
      return (
        (this.$store.state.layoutTemplate && this.$store.state.layoutTemplate.layoutSections) || []
      )
    },
    announcementBarSectionKey() {
      return this.layoutSectionOrder[0]
    },
    layoutHeaderSectionKey() {
      return this.layoutSectionOrder[1]
    },
    layoutFooterSectionKey() {
      return this.layoutSectionOrder[2]
    },
    sectionJson() {
      return sectionKey => {
        return (sectionKey && this.layoutSections[sectionKey]) || {}
      }
    },
    activeSectionId() {
      return this.$store.state.activeSectionId
    },
    isShowAnnouncementBar() {
      return (
        this.announcementBarSectionKey &&
        !this.layoutSections[this.announcementBarSectionKey].disabled
      )
    },
    sectionIsActive() {
      return sectionKey => {
        return this.activeSectionId === sectionKey ? 'active-section' : ''
      }
    },
  },
  methods: {
    getSectionEleId(sectionKey) {
      return sectionKey ? getSectionEleId(sectionKey) : ''
    },
  },
}
</script>

<style scoped lang="less">
.site-layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__header {
    position: sticky;
    top: 0;
    z-index: 100;
  }
  &__footer {
    margin-top: auto;
  }
}
</style>
