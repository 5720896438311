<template>
  <div class="op-product-list">
    <a-row
      v-if="!loading"
      type="flex"
      :gutter="{ xs: 8, sm: 16, md: 24 }"
    >
      <a-col
        v-for="item in productList"
        :key="item.id"
        :xs="12"
        :sm="12"
        :md="8"
        :lg="8"
        :xl="6"
        class="op-product-list__col"
      >
        <op-product :product-info="item"></op-product>
      </a-col>
    </a-row>
    <op-spin v-if="loading"></op-spin>
    <a-empty v-if="!loading && total === 0">
      <span
        v-if="emptyDescription"
        slot="description"
      >{{ emptyDescription }}</span>
    </a-empty>
    <div class="op-product-list__pagination">
      <a-pagination
        v-if="isShowPagination"
        v-model="currentPage"
        size="small"
        :total="total"
        :default-page-size="pageSize"
        :show-total="
          total => `${$t('common.pagination.total')} ${total} ${$t('common.pagination.items')}`
        "
        @change="changePage"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpProductList',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    productList: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    isShowPagination: {
      type: Boolean,
      default: true,
    },
    emptyDescription: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 12,
    }
  },

  computed: {},

  created() {},

  methods: {
    reloadData() {
      this.currentPage = 1
      const pagination = {
        size: this.pageSize,
        start: this.pageSize * (this.currentPage - 1),
      }
      this.$emit('changePage', pagination)
    },
    changePage() {
      const pagination = {
        size: this.pageSize,
        start: this.pageSize * (this.currentPage - 1),
      }
      this.$emit('changePage', pagination)
    },
  },
}
</script>
<style lang="less" scoped>
.op-product-list {
  width: 100%;
  &__col {
    margin-bottom: 24px;
  }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
  }
}
</style>
