<template>
  <div class="register-form">
    <div class="register-form-title">{{ $t('common.register.createAccount') }}</div>
    <a-form-model
      ref="registerForm"
      layout="vertical"
      :model="registerFormData"
      :rules="registerFormRules"
    >
      <div class="name-item">
        <div class="first-name">
          <a-form-model-item
            :label="$t('common.register.firstName')"
            prop="firstName"
          >
            <a-input
              v-model.trim="registerFormData.firstName"
              size="large"
            />
          </a-form-model-item>
        </div>
        <div class="last-name">
          <a-form-model-item
            :label="$t('common.register.lastName')"
            prop="lastName"
          >
            <a-input
              v-model.trim="registerFormData.lastName"
              size="large"
            />
          </a-form-model-item>
        </div>
      </div>
      <a-form-model-item
        :label="$t('common.register.email')"
        prop="mail"
      >
        <a-input
          v-model.trim="registerFormData.mail"
          size="large"
        />
      </a-form-model-item>

      <a-popover trigger="click">
        <div
          slot="content"
          class="register-form__password-tip"
        >{{ $t('common.register.passwordTip') }}</div>
        <a-form-model-item
          :label="$t('common.register.password')"
          prop="password"
        >
          <a-input
            v-model.trim="registerFormData.password"
            type="password"
            autocomplete="off"
            size="large"
          />
        </a-form-model-item>
      </a-popover>
      <a-form-model-item
        :label="$t('common.register.confirmPassword')"
        prop="confirmPassword"
      >
        <a-input
          v-model.trim="registerFormData.confirmPassword"
          type="password"
          autocomplete="off"
          size="large"
        />
      </a-form-model-item>
      <div
        v-if="!hideVerification"
        class="verification-code"
      >
        <div class="verification-code-input">
          <a-form-model-item
            :label="$t('common.register.verificationCode')"
            prop="verificationCode"
          >
            <a-input
              v-model.trim="registerFormData.verificationCode"
              :placeholder="$t('common.register.verificationCodeTip')"
              size="large"
            />
          </a-form-model-item>
        </div>
        <div
          v-if="sendEmailCode === 'countDown'"
          class="verification-code-send-dead"
        >
          <a-statistic-countdown
            :value="deadline"
            format="s"
            @finish="countdownFinish"
          />
          <span class="verification-code-send-dead__date">S</span>
        </div>
        <op-button
          v-if="sendEmailCode === 'first'"
          class="send-email-btn"
          @click="sendEmail"
        >{{ $t('common.register.sendEmail') }}</op-button>
        <op-button
          v-if="sendEmailCode === 'again'"
          class="send-email-btn"
          @click="sendEmail"
        >{{ $t('common.register.resend') }}</op-button>
      </div>
      <a-form-model-item>
        <!-- <a-checkbox v-model="registerFormData.agreement"></a-checkbox> -->
        <span>{{ $t('common.register.agree') }}</span>
        <op-link @click="goServiceTerms">{{ $t('common.register.termsOfService') }}</op-link>
        <span>{{ $t('common.register.and') }}</span>
        <op-link @click="goPrivacyPolicy">{{ $t('common.register.privacyPolicy') }}</op-link>
      </a-form-model-item>
    </a-form-model>
    <div class="create-btn">
      <op-button @click="register">{{ $t('common.register.create') }}</op-button>
    </div>
    <div class="go-login">
      <span class="go-login-text">{{ $t('common.register.haveAccount') }}</span>
      <op-link
        class="go-login-btn"
        @click="goLogin"
      >{{ $t('common.register.login') }}</op-link>
    </div>
  </div>
</template>

<script>
import { register, sendRegisterEmail, isHideVerification, getPublicKeyForNoToken } from '@/api'
import rsaUtil from '@/utils/rsa-util'
import {
  validateMail,
  validateRequired,
  validatePassword,
  validateRequiredAndMaxLength,
} from '@/utils/validator'
export default {
  components: {},
  props: {},
  data() {
    return {
      registerFormData: {},
      deadline: Date.now() + 1000 * 0,
      sendEmailCode: 'first',
      hideVerification: false,
    }
  },

  computed: {
    registerFormRules() {
      return {
        firstName: [validateRequiredAndMaxLength()],
        lastName: [validateRequiredAndMaxLength()],
        mail: [validateRequiredAndMaxLength(), validateMail()],
        verificationCode: [validateRequiredAndMaxLength()],
        password: [validateRequired(), validatePassword()],
        confirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('common.inputRequired')))
              } else if (value !== this.registerFormData.password) {
                callback(new Error(this.$t('common.register.confirmPasswordTip')))
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
      }
    },
  },

  created() {
    this.isHideVerification()
  },

  methods: {
    async isHideVerification() {
      const { data } = await isHideVerification()
      this.hideVerification = data
    },
    countdownFinish() {
      this.sendEmailCode = 'again'
    },
    sendEmail() {
      this.$refs.registerForm &&
        this.$refs.registerForm.validateField('mail', async errorMessage => {
          if (!errorMessage) {
            await sendRegisterEmail({
              mail: this.registerFormData.mail,
              firstName: this.registerFormData.firstName,
              lastName: this.registerFormData.lastName,
            })
            this.sendEmailCode = 'countDown'
            this.deadline = Date.now() + 1000 * 60
          }
        })
    },
    register() {
      this.$refs.registerForm &&
        this.$refs.registerForm.validate(async valid => {
          if (valid) {
            const publicKey = await getPublicKeyForNoToken({ mail: this.registerFormData.mail })
            const password = rsaUtil.encrypt(this.registerFormData.password, publicKey.data)
            await register({
              firstName: this.registerFormData.firstName,
              lastName: this.registerFormData.lastName,
              password: password,
              mail: this.registerFormData.mail,
              verificationCode: this.registerFormData.verificationCode,
            })

            this.$emit('login', {
              mail: this.registerFormData.mail,
              password: this.registerFormData.password,
            })
          }
        })
    },
    goServiceTerms() {
      window.open(
        'http://www.pandova.com/portal/service-terms?languageCode=' + this.$store.state.lang,
        '_blank'
      )
    },
    goPrivacyPolicy() {
      window.open(
        'http://www.pandova.com/portal/privacy-policy?languageCode=' + this.$store.state.lang,
        '_blank'
      )
    },
    goLogin() {
      this.$emit('changeComponent', 'loginForm')
    },
  },
}
</script>
<style lang="less" scoped>
.register-form {
  &-title {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 500;
    color: #01040d;
    text-align: center;
  }

  .name-item {
    display: flex;
    .first-name {
      flex: 1;
    }
    .last-name {
      flex: 1;
      margin-left: 24px;
    }
  }
  .verification-code {
    display: flex;
    &-input {
      flex: 1;
      margin-right: 16px;
    }
    .send-email-btn {
      margin-top: 29px;
    }
    &-send-dead {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      margin-top: 29px;
      background-color: #f5f5f5;
      border: 1px solid #d9d9d9;
      &__date {
        margin-top: 5px;
        margin-left: 2px;
      }
    }
  }
  .create-btn {
    margin: 16px auto;
    .op-button {
      width: 100%;
    }
  }
  .go-login {
    .go-login-text {
      color: #6d7b98;
    }
    .go-login-btn {
      margin-left: 16px;
    }
  }
  &__password-tip {
    max-width: 400px;
  }
  /deep/ .ant-form-item {
    margin-bottom: 8px;
  }
}
</style>
