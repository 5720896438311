<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
      <login-dialog
        v-if="loginDialogInfo.isShow"
        :current-form="loginDialogInfo.currentForm || 'LoginForm'"
        :return-to="loginDialogInfo.returnTo || ''"
      ></login-dialog>
      <whats-app></whats-app>
    </div>
  </a-config-provider>
</template>

<script>
import LoginDialog from '@/pages/account/login-dialog/index.vue'
export default {
  name: 'App',
  components: {
    LoginDialog,
  },
  computed: {
    locale() {
      return this.$i18n.getLocaleMessage(this.$store.state.lang).antLocale
    },
    loginDialogInfo() {
      return this.$store.state.loginDialogInfo
    },
  },
}
</script>

<style lang="less" scoped></style>
