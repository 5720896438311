import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import { DEFAULT_LANGUAGE } from '@/constants'
import zhCN from './lang/zh-CN'
import enUS from './lang/en-US'
import arEG from './lang/ar-EG'

Vue.use(VueI18n)

const messages = {
  'zh-CN': {
    ...zhCN,
  },
  'en-US': {
    ...enUS,
  },
  'ar-EG': {
    ...arEG,
  },
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  messages,
})

window.$t = key => i18n.t(key)

// const loadedLanguages = [DEFAULT_LANGUAGE]

function setI18nLanguage(lang) {
  i18n.locale = lang
  const dir = lang === 'ar-EG' ? 'rtl' : 'ltr'
  document.documentElement.setAttribute('lang', lang)
  document.documentElement.setAttribute('dir', dir)
  return lang
}

export function loadLanguageAsync(lang = DEFAULT_LANGUAGE) {
  return new Promise(resolve => {
    const locale = messages[lang]
    moment.updateLocale(messages[lang].momentName, locale.momentLocale)
    return resolve(setI18nLanguage(lang))
  })
}

export default i18n
