export default {
  'shoppingCart.product': 'Product',
  'shoppingCart.total': 'Total',
  'shoppingCart.stock': 'Left',
  'shoppingCart.title': 'Cart',
  'shoppingCart.quantity': 'Quantity',
  'shoppingCart.subTotal': 'Subtotal',
  'shoppingCart.overStock': 'Inventory shortage',
  'shoppingCart.back': 'Back',
  'shoppingCart.unitPrice': 'Unit Price',
  'shoppingCart.empty': 'Your cart is empty',
  'shoppingCart.all': 'All',
  'shoppingCart.changeTip': 'Some product information has changed. Your cart has been updated',
  'shoppingCart.checkout': 'Check Out',
}
