import antdArEG from 'ant-design-vue/es/locale-provider/ar_EG'
import momentAR from 'moment/locale/ar'
import account from './ar-EG/account'
import common from './ar-EG/common'
import home from './ar-EG/home'
import payment from './ar-EG/payment'
import product from './ar-EG/product'
import search from './ar-EG/search'
import shoppingCart from './ar-EG/shopping-cart'

const components = {
  antLocale: antdArEG,
  momentName: 'ar',
  momentLocale: momentAR,
}

export default {
  message: '-',
  ...components,
  ...account,
  ...common,
  ...home,
  ...payment,
  ...product,
  ...search,
  ...shoppingCart,
}
