export default {
  'payment.checkout.payNow': 'ادفع الآن',
  'payment.contactInformation.signEmail': 'ارسل لي خصومات جديدة و أخبار عن طريق البريد الإلكتروني',
  'payment.changedLink': 'تم تحديث معلومات الطلب، يرجى التحقق من تفاصيل الطلب',
  'payment.contactInformation.email': 'البريد الإلكتروني',
  'payment.orderSummary.tax': 'ضرائب',
  'payment.orderSummary.cancel': 'حذف',
  'payment.disabledLink.back': 'الرجوع إلى الصفحة الرئيسية',
  'payment.success.viewOrder': 'عرض الطلب',
  'payment.shipping.pickUpAddress': 'موقع الاستلام',
  'payment.navigation.payMethod': 'الدفع',
  'payment.shipping.pickUPInStore': 'استلام في المحل',
  'payment.orderSummary.total': 'المبلغ الإجمالي',
  'payment.disabledLink': 'عذرا، انتهت صلاحية الطلب',
  'payment.navigation.shoppingCart': 'سلة التسوق',
  'payment.contactInformation.haveAccount': 'هل لديك حساب؟',
  'payment.shipping.pleaseSelect': 'يرجى تحديد طريقة النقل',
  'payment.orderSummary.usage': 'استعمال',
  'payment.shipping.noShipping': 'عذرًا ، لا توجد طريقة شحن متاحة ، يرجى الاتصال بالتاجر.',
  'payment.checkout.providedByOpay': 'سيتم تقديم خدمة الدفع عن طريق OPay ',
  'payment.contactInformation.logout': 'تسجيل الخروج',
  'payment.shipping.continuePay': 'استمر للدفع',
  'payment.contactInformation.address': 'عنوان الشحن',
  'payment.orderSummary.title': 'إجمالي الطلب الصحيح',
  'payment.orderSummary.calcAtNext': 'احسب في الخطوة التالية',
  'payment.contactInformation.continueShipping': 'استمر لاختيار طريقة الشحن',
  'payment.contactInformation': 'معلومات الاتصال',
  'payment.shipping.method': 'طريقة التسليم',
  'payment.navigation.information': 'التفاصيل',
  'payment.shipping.free': 'طريقة النقل المجانية',
  'payment.navigation.shipping': 'الشحن',
  'payment.checkout.method': 'الطريقة',
  'payment.backShippingCart': 'العودة إلى عربة التسوق',
  'payment.orderSummary.subTotal': 'المجموع الفرعي',
  'payment.update': 'تعديل',
  'payment.shipping.logistics': 'توصيل بالبريد',
  'payment.orderSummary.discountCode': 'كود الخصم',
  'payment.orderSummary.discount': 'مبلغ الخصم',
  'payment.success.title': 'المعلومات',
  'payment.contactInformation.formSelectTip': 'يرجى تحديد عنصر واحد في القائمة',
  'payment.checkout.noCheckout': 'لا توجد طريقة دفع في هذا المتجر ، يرجى الاتصال بالتاجر',
  'payment.contactInformation.login': 'تسجيل الدخول',
  'payment.shipping.deliverTo': 'تسليم إلى',
  'payment.orderSummary.shippingRate': 'تكلفة الشحن',
  'payment.success.continueShopping': 'تابع التسوق',
  'payment.contactInformation.formInputTip': 'يرجى ملء هذا الحقل',
}
