<template>
  <div class="op-menu-item">
    <router-link
      class="menu-item-title"
      :to="{ path: menuItem.path, query: menuItem.query || {} }"
      @click.native="clickMenu"
    >{{ menuItem.title }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'OpMenuItem',
  components: {},
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },

  computed: {},

  methods: {
    clickMenu() {
      this.$emit('clickMenu', this.menuItem)
    },
  },
}
</script>
<style lang="less" scoped>
.op-menu-item {
  padding: 16px 0;
  border-bottom: 1px solid #f4f6f8;
  a {
    display: block;
    width: 100%;
    color: #000;
  }
}
</style>
