<template>
  <div class="header-mobile-operation">
    <a-icon
      :style="{ fontSize: '24px', cursor: 'pointer' }"
      class="header-mobile-operation__search"
      type="search"
      @click="jumpLink('/search')"
    />
    <op-popup>
      <a-icon
        :style="{ fontSize: '24px', cursor: 'pointer' }"
        type="ellipsis"
      />
      <div slot="content">
        <div
          class="header-mobile-operation__item header-mobile-operation__cart"
          @click="jumpLink('/cart')"
        >
          <a-icon
            :style="{ fontSize: '24px' }"
            type="shopping-cart"
          />
          <div
            v-show="cartNumber"
            class="header-mobile-operation__cart-number"
          >{{ actualShowCartNumber }}</div>
          <span class="header-mobile-operation__item-text">{{ $t('common.header.shoppingCart') }}</span>
        </div>
        <div
          class="header-mobile-operation__item"
          @click="goMyAccount"
        >
          <a-icon
            :style="{ fontSize: '24px' }"
            type="user"
          />
          <span class="header-mobile-operation__item-text">{{ $t('common.header.account') }}</span>
        </div>
      </div>
    </op-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { jumpLink } from '@/utils/index'
export default {
  props: {},
  data() {
    return {}
  },

  computed: {
    ...mapState(['cartNumber']),
    actualShowCartNumber() {
      return this.cartNumber > 99 ? '99+' : this.cartNumber
    },
  },

  methods: {
    jumpLink,
    goMyAccount() {
      this.$emit('goMyAccount')
    },
  },
}
</script>
<style lang="less" scoped>
.header-mobile-operation {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  &__search {
    margin-right: 16px;
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    color: var(--layout-header-color-text);
    word-break: keep-all;
    cursor: pointer;
    &-text {
      margin-left: 24px;
      line-height: 24px;
    }
  }
  .header-mobile-operation__item:not(:last-child) {
    border-bottom: 1px solid rgba(38, 38, 38, 0.08);
  }
  &__cart {
    position: relative;
    &-number {
      position: absolute;
      top: 8px;
      left: 16px;
      padding: 2px 4px;
      font-size: 12px;
      line-height: 12px;
      color: rgb(255, 255, 255);
      text-align: center;
      background: rgb(212, 0, 0);
      border-radius: 4px;
    }
  }
}
</style>
